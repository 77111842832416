/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import FiltersContext from 'context/FiltersContext';
import { type Coordinates } from 'models/Coordinates';
import React, { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import styled from 'styled-components';
import { type FiltersContextType } from 'types';
import { showErrorNotification } from 'services/NotificationService';

const StyledDiv = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  .search-input {
    width: 100%;
    padding: 11px;
    font-size: 13px;
    color: var(--rak-palette-textSearch);
    &.invalid {
      border: 1px solid var(--rak-palette-bd_red);
    }
  }
  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    background: #fff;
    border-bottom: none;
    border-top: none;
    padding-left: 0;
    z-index: 9999;
    top: 100%;
    left: 0;
    right: 0;

    li {
      list-style: none;
      padding-left: 0;
      a {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: var(--rak-palette-textSearch);
        cursor: pointer;
        display: block;
        padding: 0.625rem 0.3125rem 0.625rem 1.25rem;

        &:hover {
          background: rgba(115, 171, 255, 0.2);
        }
      }
    }
  }

  .search-input {
    width: 90%;
    height: 2rem;
    padding-left: 0.75rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 0.6875rem;
    color: var(--rak-palette-textSearch);
  }

  .btn-rak-primary {
    padding: 0;
    height: 2rem;
    width: 2rem;
    border-radius: 3px;
    margin-top: 0.65rem;
    margin-left: 0.4rem;

    .search-icon {
      cursor: pointer;
      position: relative;
      top: -1px;
    }
  }

  .coords {
    position: relative;
    display: inline-block;

    .form-control-rak {
      margin: 0.625rem;
      position: relative;
      background-color: rgb(245, 245, 245);
    }

    .form-label {
      margin-bottom: 0px;
      font-size: 8px;
      font-weight: 500;
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 1rem;
      padding: 2px;
      width: 3rem;
      z-index: 3;
      background-color: rgb(245, 245, 245);
    }
  }
`;

const CoordsInput: React.FC = () => {
  const { t } = useTranslation();

  const { filtersContext, setFiltersContext } =
    useContext<FiltersContextType>(FiltersContext);

  const { coordinates } = filtersContext;

  const isCoordinate = (long: number, lat: number): boolean => {
    const latMin = 42.3404785156;
    const latMax = 51.0971191406;

    const longMin = -4.7625;
    const longMax = 8.14033203125;

    const longOk = long >= longMin && long <= longMax;
    const latOk = lat >= latMin && lat <= latMax;

    return longOk && latOk;
  };

  const handleAddressByCoords = (): void => {
    let currentAddress: Coordinates = {
      label: '',
      lng: 0,
      lat: 0,
    };

    if (
      coordinates !== undefined &&
      isCoordinate(coordinates?.lng, coordinates?.lat)
    ) {
      currentAddress = {
        lng: coordinates.lng,
        lat: coordinates.lat,
      };
      handleSelected(currentAddress);
    } else {
      showErrorNotification(t('strategy.search.errors.address'));
    }
  };

  const handleSelected = (address: Coordinates): void => {
    setFiltersContext({
      ...filtersContext,
      coordinates: address,
    });
  };

  const onChangeLong = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFiltersContext({
      ...filtersContext,
      coordinates: {
        lng: Number(e.target.value),
        lat: coordinates?.lat ?? 0,
      },
    });
  };

  const onChangeLat = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFiltersContext({
      ...filtersContext,
      coordinates: {
        lng: coordinates?.lng ?? 0,
        lat: Number(e.target.value),
      },
    });
  };

  return (
    <StyledDiv className="autocomplete mx-2">
      <Row>
        <Col md={5} sm={5} xs={5}>
          <Form.Group as={Row} className="coords" controlId="lat">
            <Form.Label>{`Latitude`}</Form.Label>
            <Form.Control
              className={`search-input search text-truncate`}
              bsPrefix="form-control-rak"
              onChange={onChangeLat}
              name="lat"
              type="number"
              title={coordinates?.lat.toString() ?? ''}
              placeholder="0"
            />
          </Form.Group>
        </Col>
        <Col md={5} sm={5} xs={5}>
          <Form.Group as={Row} className="coords" controlId="lng">
            <Form.Label>{`Longitude`}</Form.Label>
            <Form.Control
              className={`search-input search text-truncate`}
              bsPrefix="form-control-rak"
              onChange={onChangeLong}
              name="lng"
              type="number"
              title={coordinates?.lng.toString() ?? ''}
              placeholder="0"
            />
          </Form.Group>
        </Col>
        <Col md={2} sm={2} xs={2}>
          <Button
            className="btn-rak-primary"
            variant="primary"
            bsPrefix="btn-rak"
            size="sm"
            disabled={coordinates?.lat === 0 && coordinates?.lng === 0}
            onClick={handleAddressByCoords}
          >
            <Icon iconName="Search" color="white" className="search-icon" />
          </Button>
        </Col>
      </Row>
    </StyledDiv>
  );
};
export default CoordsInput;
