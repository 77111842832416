/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react';

import {
  type IStrategyQuote,
  type IPointCoords,
  type AlternativePaths,
} from 'models/Quote';
import QuoteMarker from './QuoteMarker';
import { FeatureGroup, useMap } from 'react-leaflet';
import QuotePolyline from './QuotePolyline';
import { type LatLngBoundsExpression } from 'leaflet';

interface MapQuoteStrategyChildProps {
  pointCoords: IPointCoords;
  strategy: IStrategyQuote;
  pathSelected: AlternativePaths;
}

export const MapQuoteStrategyChild: React.FC<MapQuoteStrategyChildProps> = ({
  strategy,
  pointCoords,
  pathSelected,
}): React.ReactElement => {
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
    const bounds = pathSelected.polylineCoords as LatLngBoundsExpression;
    map.fitBounds(bounds);
  }, [pathSelected]);

  return (
    <>
      <QuoteMarker
        lng={Number(pointCoords?.lng)}
        lat={Number(pointCoords?.lat)}
        label={pointCoords?.label}
        position={true}
      />
      {strategy && (
        <FeatureGroup>
          <QuotePolyline
            positions={pathSelected?.polylineCoords}
            id={strategy?.id}
            power={strategy?.power}
            label={strategy?.id}
            lng={strategy?.pointCoords?.lng}
            lat={strategy?.pointCoords?.lat}
            point={strategy.point}
            targetType={strategy.targetType}
          >
            <QuoteMarker
              label={strategy?.pointCoords.label}
              lng={strategy?.pointCoords.lng}
              lat={strategy?.pointCoords.lat}
              id={strategy?.id}
              position={false}
              point={strategy.point}
              targetType={strategy.targetType}
              discarded={strategy.discarded}
            />
          </QuotePolyline>
        </FeatureGroup>
      )}
    </>
  );
};

export default MapQuoteStrategyChildProps;
