/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { useTranslation } from 'react-i18next';

interface DatePickerProps {
  onChange: (selectedDate: Date | null) => void;
}

const StyledDatePicker = styled(DatePicker)`
  .react-date-picker {
    padding: 0.15rem 0.75rem 0.375rem 0.25rem;
    height: 2rem;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: rgba(118, 118, 118, 0.1) !important;
  }
  .react-calendar__tile--now {
    background: rgb(0, 156, 223);
    color: #fff;
  }
  button.react-date-picker__button.react-date-picker__calendar-button {
    display: none;
  }
  button.react-date-picker__button.react-date-picker__clear-button {
    position: relative;
    right: -0.8rem;
  }
  .react-date-picker__inputGroup__input:focus-visible {
    outline: none;
  }
`;

const CustomDatePicker: React.FC<DatePickerProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <Form.Group controlId="datepicker">
      <Form.Label>{t('strategy.form.date')}</Form.Label>
      <StyledDatePicker
        value={selectedDate}
        onChange={handleDateChange}
        format="dd/MM/yyyy"
        className="form-control"
        minDate={new Date()}
        locale="fr-FR"
      />
    </Form.Group>
  );
};

export default CustomDatePicker;
