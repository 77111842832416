export enum ClientTypeLabels {
  PRODUCER = 'Producteur / Stockeur',
  CONSUMER = 'Consommateur',
  DISTRIBUTOR = 'Distributeur',
}

export enum StationTypeLabels {
  AIRLINE = 'Aérien',
  UNDERGROUND = 'Souterrain',
}
export enum LineTypeLabels {
  AIRLINE = 'Aérienne',
  UNDERGROUND = 'Souterraine',
}
export enum ConnectionMessageTypeLabels {
  ANTENNA = 'Piquage',
  PIQUAGE = 'pique sur une liaision',
}
export enum ClientTypes {
  PRODUCER = 'PRODUCER',
  CONSUMER = 'CONSUMER',
  DISTRIBUTOR = 'DISTRIBUTOR',
}
export enum StrategyTypes {
  LINE = 'LINE',
  STATION = 'STATION',
}
export enum StationLineTypes {
  AIRLINE = 'AIRLINE',
  UNDERGROUND = 'UNDERGROUND',
}
export enum ConnectionMessageTypes {
  ANTENNA = 'ANTENNA',
  PIQUAGE = 'PIQUAGE',
}
export enum EneryTypeLabels {
  RENEWABLE = 'Renouvelable',
  NON_RENEWABLE = 'Non renouvelable',
}

export enum TraceTypeLabels {
  TRACE1 = 'A',
  TRACE2 = 'B',
  TRACE3 = 'C',
  TRACE4 = 'D',
  TRACE5 = 'E',
  TRACE6 = 'F',
  TRACE7 = 'G',
  TRACE8 = 'H',
  TRACE9 = 'I',
  TRACE10 = 'J',
  TRACE11 = 'K',
  TRACE12 = 'L',
  TRACE13 = 'M',
  TRACE14 = 'N',
  TRACE15 = 'O',
  TRACE16 = 'P',
  TRACE17 = 'Q',
  TRACE18 = 'R',
  TRACE19 = 'S',
  TRACE20 = 'T',
  TRACE21 = 'U',
  TRACE22 = 'V',
  TRACE23 = 'W',
  TRACE24 = 'X',
  TRACE25 = 'Y',
  TRACE26 = 'Z',
}

export enum VariantBGColor {
  'R' = '#c7c1ca',
  'S-U' = '#9479a0',
  'U+' = '#3b005c',
  'U' = '#5e2e74',
  'Chaude' = '#f47f7f',
  'Froide' = '#7fc9ff',
  'Inter' = '#9cc89b',
}

export enum VariantClassLabels {
  'R' = 'rural',
  'S-U' = 'semi-urban',
  'U+' = 'very-urban',
  'U' = 'urban',
  'Chaude' = 'hot',
  'Froide' = 'cold',
  'Inter' = 'inter',
}
