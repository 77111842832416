/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BorderStyle,
  Document,
  Packer,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import { Loading } from 'shared/Loading';

import {
  type IStrategyQuote,
  type IQuote,
  type Trace,
  type AlternativePaths,
} from 'models/Quote';

import { Dropdown } from 'react-bootstrap';
import { ClientTypeLabels } from 'enums';
import { formattedDate } from 'utils';
import { Icon } from 'shared/Icon';
import {
  headerDoc,
  footerDoc,
  createHeading,
  getdistance,
  getBackgroundColor,
  formatNumbersForDisplay,
  createHeadingText,
  getCostValue,
  typeWorks,
  checkTypePoste,
  createTitleText,
  createTitle,
  createInfoRequest,
  createTitleZone,
  createCostsEstimatedDetails,
  createTitleTraces,
  createTracesBar,
  supplementsTable,
  createCostsMinMaxDetails,
  checkTypePiquage,
  createBreak,
  borders,
} from './generate-docx';
import { api } from 'services/api';

interface QuoteDetailsProps {
  detailsQuote?: IQuote;
}

const QuoteGenerateWord: React.FC<QuoteDetailsProps> = ({
  detailsQuote,
}): React.ReactElement => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);

  const getEstimadedCost = (typeStrategy: string, costsDetails: any): any => {
    let total = '';
    if (typeStrategy === 'SUBSTATION' && costsDetails.isCostInitialized) {
      total = `${getCostValue(costsDetails.total.p50)} - ${getCostValue(
        costsDetails.total.p70,
      )}`;
    }
    if (typeStrategy === 'SUBSTATION' && !costsDetails.isCostInitialized) {
      total = `${getCostValue(costsDetails.total.min)} - ${getCostValue(
        costsDetails.total.max,
      )}`;
    }
    if (typeStrategy === 'PYLON') {
      total = `${getCostValue(costsDetails.total.p50)} - ${getCostValue(
        costsDetails.total.p70,
      )}`;
    }
    return total;
  };

  const getClientType = (): any => {
    return ClientTypeLabels[
      detailsQuote?.clientType as keyof typeof ClientTypeLabels
    ];
  };

  // useEffect(() => {
  //   // setLoading(true);

  //   // const mapList: StrategyMap[] = [];

  //   if (detailsQuote !== undefined && detailsQuote.strategies.length > 0) {
  //     setTimeout(() => {
  //       // Create a blob from the document
  //       detailsQuote.strategies.map(
  //         async (strategy: IStrategyQuote, index: number) => {
  //           const image = await captureMapImage(strategy.id);
  //           console.log('image', image);
  //         },
  //       );

  //       // setStrategyMap(mapList);
  //       // setLoading(false);
  //     }, 10000);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [detailsQuote]);

  // ************************************** DIVERS FUNCTIONS  ********************************************/

  // ****************************  CONTROL INFOS  ***************************************/

  const checkContact =
    detailsQuote?.contactName != null &&
    detailsQuote?.email !== '' &&
    detailsQuote?.phone !== '' &&
    detailsQuote?.siret !== '' &&
    detailsQuote?.companyName;

  // DETAILS DEMANDE
  const createQuoteInfo = (): Table => {
    const rows = [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            children: [createTitle(t(`quote.details.generate_word.request`))],
          }),
        ],
      }),
    ];
    if (detailsQuote?.address !== undefined) {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t(`quote.details.generate_word.address`),
                  detailsQuote?.address,
                ),
              ],
            }),
          ],
        }),
      );
    }
    rows.push(
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            children: [
              createInfoRequest(t(`quote.details.type`), getClientType()),
            ],
          }),
        ],
      }),
    );
    if (detailsQuote?.dateMiseEnService !== undefined) {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t(`quote.details.date`),
                  formattedDate(detailsQuote?.dateMiseEnService),
                ),
              ],
            }),
          ],
        }),
      );
    }
    if (detailsQuote?.power !== undefined) {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t(`quote.details.power`),
                  `${detailsQuote?.power} MW`,
                ),
              ],
            }),
          ],
        }),
      );
    }
    if (checkContact !== undefined) {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createTitle(t(`quote.details.generate_word.applicant`)),
              ],
            }),
          ],
        }),
      );
    }
    console.log(detailsQuote);
    if (detailsQuote?.contactName !== '') {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t([`quote.details.generate_word.name`]),
                  detailsQuote?.contactName,
                ),
              ],
            }),
          ],
        }),
      );
    }
    if (detailsQuote?.email !== '') {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t([`quote.details.generate_word.email`]),
                  detailsQuote?.email,
                ),
              ],
            }),
          ],
        }),
      );
    }
    if (detailsQuote?.phone !== '') {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t([`quote.details.generate_word.phone`]),
                  detailsQuote?.phone,
                ),
              ],
            }),
          ],
        }),
      );
    }
    if (detailsQuote?.companyName !== '') {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t([`quote.details.generate_word.company_name`]),
                  detailsQuote?.companyName,
                ),
              ],
            }),
          ],
        }),
      );
    }
    if (detailsQuote?.siret !== '') {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t([`quote.details.generate_word.siret`]),
                  detailsQuote?.siret,
                ),
              ],
            }),
          ],
        }),
      );
    }
    return new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      columnWidths: [100],
      borders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Table({
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  borders,
                  columnWidths: [100],
                  rows,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  };

  // DETAILS SRATEGY

  // ****************************  CONTROL TRACES  ***************************************/

  const createInfosTracesBar = (traces: Trace[], zone: string): any => {
    const progressFiltred = traces.filter(
      (item) => item.ratio !== null && Number(item.ratio) > 0,
    );

    const checkPopulation =
      progressFiltred !== undefined && progressFiltred.length > 0;
    if (checkPopulation) {
      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            children: [
              new Table({
                borders,
                columnWidths:
                  zone === 'population' ? [25, 25, 25, 25] : [33.3, 33.3, 33.3],
                rows: [
                  new TableRow({
                    children: [
                      ...progressFiltred.map((trace) => {
                        return new TableCell({
                          width: {
                            size: zone === 'population' ? 25 : 33.3,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Table({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              columnWidths: [30, 70],
                              borders,
                              rows: [
                                new TableRow({
                                  children: [
                                    new TableCell({
                                      borders: {
                                        top: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        start: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        left: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        bottom: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        end: {
                                          style: BorderStyle.NONE,
                                          space: 3,
                                        },
                                        right: {
                                          style: BorderStyle.NONE,
                                          space: 3,
                                        },
                                      },
                                      width: {
                                        size: 30,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      children: [
                                        new Paragraph({
                                          shading: {
                                            type: ShadingType.CLEAR,
                                            fill: getBackgroundColor(
                                              trace.name,
                                            ),
                                          },
                                        }),
                                      ],
                                    }),
                                    new TableCell({
                                      width: {
                                        size: 70,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `${formatNumbersForDisplay(
                                                trace.length_km,
                                              )} km`,
                                              bold: false,
                                              size: 19,
                                              font: 'Calibri',
                                            }),
                                          ],
                                        }),
                                      ],
                                    }),
                                  ],
                                }),
                                new TableRow({
                                  children: [
                                    new TableCell({
                                      borders: {
                                        top: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        start: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        left: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        bottom: {
                                          style: BorderStyle.NONE,
                                          space: 0,
                                        },
                                        end: {
                                          style: BorderStyle.NONE,
                                          space: 3,
                                        },
                                        right: {
                                          style: BorderStyle.NONE,
                                          space: 3,
                                        },
                                      },
                                      width: {
                                        size: 30,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      children: [],
                                    }),
                                    new TableCell({
                                      width: {
                                        size: 70,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      children: [
                                        new Paragraph({
                                          children:
                                            zone === 'population'
                                              ? [
                                                  new TextRun({
                                                    text: `${trace.name} ${t([
                                                      `quote.details.generate_word.infos_population.${trace.name}`,
                                                    ])}`,
                                                    bold: false,
                                                    size: 19,
                                                    font: 'Calibri',
                                                  }),
                                                ]
                                              : [],
                                        }),
                                      ],
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        });
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      });
    }
  };
  //* *********************** Display strategies ******************************* */
  const strategies =
    detailsQuote?.strategies != null && detailsQuote?.strategies.length > 0
      ? detailsQuote?.strategies
      : [];

  // DISPLAY LIST INFOS STRATEGY
  const generateInfosStrategyList = (
    strategies: IStrategyQuote[],
  ): TableRow[] =>
    strategies.map((strategy: IStrategyQuote) => {
      const alternative = strategy.alternativePaths.find(
        (item: any) =>
          // eslint-disable-next-line eqeqeq
          item.uid == strategy.selectedPath,
      );

      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            children: [
              new Table({
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                borders,
                columnWidths: [100],
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          createTitleText(
                            strategy,
                            t(`quote.details.generate_word.strategy`),
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          createInfoRequest(
                            `${t('quote.details.generate_word.distance')}`,
                            getdistance(alternative?.distance),
                            true,
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          createInfoRequest(
                            `${t('quote.details.generate_word.cost')}`,
                            strategy.targetType !== undefined &&
                              strategy.costsDetails !== undefined
                              ? getEstimadedCost(
                                  strategy.targetType,
                                  strategy.costsDetails,
                                )
                              : '',
                            true,
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          createInfoRequest(
                            `${t([
                              `quote.details.generate_word.strategy_type_labels.${strategy.targetType}`,
                            ])}`,
                            strategy.point.code,
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [createInfoRequest(``, strategy.point.name)],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          createInfoRequest(
                            `Site`,
                            strategy.point.details,
                            true,
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          supplementsTable(
                            strategy,
                            t('quote.details.generate_word.supplements'),
                            t([
                              `quote.details.generate_word.connector_type_labels.${strategy.targetType}`,
                            ]),
                            t('quote.details.generate_word.tension'),
                            t('quote.details.generate_word.commentary'),
                          ),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      });
    });

  //* *********************** generate Details Strategy List ******************************* */
  const checkTrace = (
    strategy: IStrategyQuote,
    alternative?: AlternativePaths,
  ): boolean => {
    return (
      strategy !== undefined &&
      alternative !== undefined &&
      alternative?.pathInfo !== undefined
    );
  };

  //* *********************** generate Word Document ******************************* */

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const generateWordDocument = async () => {
    setLoading(true);
    const doc = new Document({
      compatibility: {
        version: 15,
        doNotExpandShiftReturn: true,
      },
      sections: [
        {
          properties: {
            titlePage: true,
          },
          footers: footerDoc,
          children: [
            detailsQuote !== undefined &&
              headerDoc(t('quote.details.generate_word.title'), detailsQuote),
            createBreak(0.6),
            createQuoteInfo(),
            createBreak(0.4),
            createHeading(
              `${t('quote.details.generate_word.summary_of_the_quote')}`,
            ),
            new Table({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders,
              columnWidths: [100],
              rows: [...generateInfosStrategyList(strategies)],
            }),
            createHeading(`${t('quote.details.generate_word.details_quote')}`),
            /** ******** Details STARTEGYS ********* */
            new Table({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders,
              columnWidths: [100],
              rows: [
                ...strategies
                  .map((strategy: IStrategyQuote) => {
                    const arr: TableRow[] = [];

                    const alternative = strategy.alternativePaths.find(
                      (item: any) =>
                        // eslint-disable-next-line eqeqeq
                        item.uid == strategy.selectedPath,
                    );

                    const populationList = alternative?.pathInfo.population;
                    const climateList = alternative?.pathInfo.climate;

                    /** ******** Details TRACE LINE & PROGRESS BAR ********* */

                    arr.push(
                      strategy !== undefined &&
                        alternative !== undefined &&
                        createTitleTraces(
                          strategy,
                          alternative,
                          t(`quote.details.generate_word.strategy`),
                          t('quote.details.generate_word.titleTrace'),
                          t('quote.details.generate_word.total'),
                        ),
                    );

                    /** ********** Strategy Map*************** */
                    // const mapImage = await captureMapAsImage(strategy.id);
                    // if (mapImage !== undefined) {
                    //   console.log(mapImage);

                    //   // arr.push(
                    //   //   new TableRow({
                    //   //     children: [
                    //   //       new TableCell({
                    //   //         children: [createStrategyMap(mapImage)],
                    //   //       }),
                    //   //     ],
                    //   //   }),
                    //   // );
                    // }

                    if (
                      checkTrace(strategy, alternative) &&
                      climateList !== undefined
                    ) {
                      arr.push(
                        createTitleZone(
                          t(`quote.details.generate_word.climate`),
                        ),
                        createTracesBar(climateList, 'climate'),
                        createInfosTracesBar(climateList, 'climate'),
                      );
                    }

                    if (
                      checkTrace(strategy, alternative) &&
                      populationList !== undefined
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [createBreak(0.2)],
                            }),
                          ],
                        }),
                        createTitleZone(
                          t(`quote.details.generate_word.population`),
                        ),
                        createTracesBar(populationList, 'population'),
                        createInfosTracesBar(populationList, 'population'),
                      );
                    }

                    /** ****************  TECHNICAL CHARACTERISTICS ******************* */
                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 100,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              createTitle(
                                t(
                                  `quote.details.generate_word.technical_characteristics.title`,
                                ),
                              ),
                            ],
                          }),
                        ],
                      }),
                    );

                    if (alternative?.cable?.type !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `${t([
                                    `quote.details.generate_word.technical_characteristics.type_cable`,
                                  ])}`,
                                  alternative.cable?.type,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.poste_type !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `${t([
                                    `quote.details.generate_word.technical_characteristics.poste_type`,
                                  ])}`,
                                  strategy?.poste_type,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.nbr_busbar !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `${t([
                                    `quote.details.generate_word.technical_characteristics.nbr_busbar`,
                                  ])}`,
                                  strategy?.nbr_busbar,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    /** ****************  COSTS ESTIMATED TRACE / POSTE INIT / NO INIT / PIQUAGE ******************* */

                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 100,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              createTitle(
                                t(
                                  `quote.details.generate_word.cost_estimated.title`,
                                ),
                              ),
                            ],
                          }),
                        ],
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 100,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              createHeadingText(
                                `${t(
                                  'quote.details.generate_word.cost_estimated.titleTrace',
                                )}`,
                              ),
                            ],
                          }),
                        ],
                      }),
                    );

                    /** ****************  COSTS ESTIMATED TRACE ******************* */

                    if (strategy.costsDetails?.trace.seche !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.seche`,
                                  ),
                                  getCostValue(
                                    strategy.costsDetails.trace.seche,
                                  ),
                                  false,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.costsDetails?.trace.cmo !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.cmo`,
                                  ),
                                  getCostValue(strategy.costsDetails.trace.cmo),
                                  false,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.costsDetails?.trace.base !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.base`,
                                  ),
                                  getCostValue(
                                    strategy.costsDetails.trace.base,
                                  ),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.costsDetails?.trace.p50 !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.p50`,
                                  ),
                                  getCostValue(strategy.costsDetails.trace.p50),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.costsDetails?.trace.p70 !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.p70`,
                                  ),
                                  getCostValue(strategy.costsDetails.trace.p70),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    /** ****************  COSTS ESTIMATED POSTE / PIQUAGE ******************* */
                    if (strategy.targetType !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createHeadingText(
                                  typeWorks(strategy.targetType),
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    /** ******    Costs Estimated Poste not Init (Min & Max) ********** */

                    if (
                      strategy.costsDetails.isCostInitialized === false &&
                      strategy.costsDetails.poste.min !== undefined &&
                      strategy.costsDetails.poste.max !== undefined &&
                      strategy.targetType !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsMinMaxDetails(
                                  strategy.costsDetails.poste.min,
                                  strategy.costsDetails.poste.max,
                                  t([
                                    `quote.details.generate_word.cost_estimated.estimated`,
                                  ]),
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    /** ******    Costs Estimated Poste Init ********** */

                    if (
                      strategy.costsDetails.isCostInitialized === true &&
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.poste !== undefined &&
                      strategy.costsDetails.poste.seche !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.ctp`,
                                  ),
                                  getCostValue(
                                    strategy.costsDetails.poste.seche,
                                  ),
                                  false,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    /** ********** Facteur Contrainte ********** */
                    if (
                      strategy.costsDetails.isCostInitialized === true &&
                      strategy.targetType !== undefined &&
                      strategy.poste_facteur_contrainte !== undefined &&
                      strategy.costsDetails.poste.contraintes !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  `${t(
                                    `quote.details.generate_word.cost_estimated.seche`,
                                  )} ${`(${strategy.poste_facteur_contrainte}%)`}`,
                                  getCostValue(
                                    strategy.costsDetails.poste.contraintes,
                                  ),
                                  false,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (
                      strategy.costsDetails.isCostInitialized === true &&
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.poste !== undefined &&
                      strategy.costsDetails.poste.cmo !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.cmo`,
                                  ),
                                  getCostValue(strategy.costsDetails.poste.cmo),
                                  false,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (
                      strategy.costsDetails.isCostInitialized === true &&
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.poste !== undefined &&
                      strategy.costsDetails.poste.base !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.base`,
                                  ),
                                  getCostValue(
                                    strategy.costsDetails.poste.base,
                                  ),
                                  false,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (
                      strategy.costsDetails.isCostInitialized === true &&
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.poste !== undefined &&
                      strategy.costsDetails.poste.p50 !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.p50`,
                                  ),
                                  getCostValue(strategy.costsDetails.poste.p50),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (
                      strategy.costsDetails.isCostInitialized === true &&
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.poste !== undefined &&
                      strategy.costsDetails.poste.p70 !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.p70`,
                                  ),
                                  getCostValue(strategy.costsDetails.poste.p70),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (
                      strategy.costsDetails.isCostInitialized === true &&
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.poste !== undefined &&
                      strategy.costsDetails.poste.intercalaires !== undefined &&
                      checkTypePoste(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.intercalaires`,
                                  ),
                                  getCostValue(
                                    strategy.costsDetails.poste.intercalaires,
                                  ),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    /** *********** COSTS PIQUAGE *************** */

                    if (
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.piquage !== undefined &&
                      strategy.costsDetails.piquage.forfait !== undefined &&
                      checkTypePiquage(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.forfait`,
                                  ),
                                  getCostValue(
                                    strategy.costsDetails.piquage.forfait,
                                  ),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (
                      strategy.targetType !== undefined &&
                      strategy.costsDetails.piquage !== undefined &&
                      strategy.costsDetails.piquage.cable !== undefined &&
                      checkTypePiquage(strategy.targetType)
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createCostsEstimatedDetails(
                                  t(
                                    `quote.details.generate_word.cost_estimated.cable`,
                                  ),
                                  getCostValue(
                                    strategy.costsDetails.piquage.cable,
                                  ),
                                  true,
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    return arr;
                  })
                  .reduce((prev, curr) => prev.concat(curr), []),
              ],
            }),
          ],
        },
      ],
    });

    // Create a blob from the document
    // try {
    //   // Convert the document to a binary blob
    //   const blob = await Packer.toBuffer(doc);

    //   // Create a blob URL for the blob
    //   const url = window.URL.createObjectURL(new Blob([blob]));

    //   // Create a link element and trigger a click event to download the document
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', `DEVIS-${detailsQuote?.id}.docx`);
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   setLoading(false);
    // } catch (error) {
    //   console.error('Error exporting to Word:', error);
    //   setLoading(false);
    // }

    void Packer.toBlob(doc).then((blob) => {
      setLoading(false);
      if (detailsQuote?.id !== undefined) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `DEVIS-${detailsQuote?.id}.docx`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  };

  const handleKmlDownload = (): void => {
    const fileName = `DEVIS-${detailsQuote?.uid}.kml`;
    api
      .get(`devis/${detailsQuote?.uid}/kml?timestamp=${new Date().getTime()}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('File could not be downloaded:', error);
      });
  };

  const handleGeoJsonDownload = (): void => {
    const fileName = `DEVIS-${detailsQuote?.uid}.geojson`;
    api
      .get(
        `devis/${detailsQuote?.uid}/geojson?timestamp=${new Date().getTime()}`,
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('File could not be downloaded:', error);
      });
  };

  return (
    <>
      {isLoading && <Loading message={`Loading ...`} />}
      <Dropdown data-bs-theme="light" className="me-2">
        <Dropdown.Toggle
          id={`dropdown-variants-primary`}
          className="btn-rak-primary"
        >
          <Icon iconName="Download" color="white" className="icon me-2" />
          &nbsp;&nbsp;
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={generateWordDocument}>
            {`DEVIS-${detailsQuote?.id}.docx`}
          </Dropdown.Item>
          {detailsQuote?.uid !== undefined && (
            <Dropdown.Item onClick={handleGeoJsonDownload}>
              {`DEVIS-${detailsQuote?.id}.geojson`}
            </Dropdown.Item>
          )}
          {detailsQuote?.uid !== undefined && (
            <Dropdown.Item onClick={handleKmlDownload}>
              {`DEVIS-${detailsQuote?.id}.kml`}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default QuoteGenerateWord;
