/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react';
// import html2canvas from 'html2canvas';

import {
  type IStrategyQuote,
  type IPointCoords,
  type AlternativePaths,
} from 'models/Quote';
import QuoteMarker from '../../map/QuoteMarker';
import { FeatureGroup, useMap } from 'react-leaflet';
import QuotePolyline from '../../map/QuotePolyline';
import { type LatLngBoundsExpression } from 'leaflet';

interface MapScreenshotChildProps {
  pointCoords: IPointCoords;
  strategy: IStrategyQuote;
  pathSelected: AlternativePaths;
}

export const MapScreenshotChild: React.FC<MapScreenshotChildProps> = ({
  strategy,
  pointCoords,
  pathSelected,
}): React.ReactElement => {
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
    const bounds = pathSelected.polylineCoords as LatLngBoundsExpression;
    map.fitBounds(bounds);

    // void handleDownloadImage();
  }, [pathSelected, map]);

  // const captureImage = useCallback(async () => {
  //   // Wait for the maps to become visible before capturing
  //   await new Promise((resolve) => setTimeout(resolve, 10000));

  //   // Use html2canvas to capture the image
  //   // const element = map.getContainer();
  //   // if (element !== null) {
  //   //   console.log(element.offsetWidth, element.offsetHeight);
  //   //   void html2canvas(element, {
  //   //     useCORS: true,
  //   //     allowTaint: true,
  //   //     width: element.offsetWidth,
  //   //     height: element.offsetHeight,
  //   //   }).then((canvas) => {
  //   //     console.log('image link:', canvas.toDataURL('image/png'));
  //   //     // document.body.appendChild(canvas);
  //   //   });
  //   // }
  // }, []);

  // const handleDownloadImage = async () => {
  //   await new Promise((resolve) => setTimeout(resolve, 10000));
  //   const mapContainer = map.getContainer();
  //   const mapSize = mapContainer.getBoundingClientRect();
  //   const canvas = document.createElement('canvas');
  //   // const context = canvas.getContext('2d');

  //   canvas.width = mapSize.width;
  //   canvas.height = mapSize.height;

  //   void html2canvas(mapContainer).then((canvas) => {
  //     const image = canvas.toDataURL('image/png');
  //     console.log('image link:', image);
  //     // const link = document.createElement('a');
  //     // link.href = image;
  //     // link.download = 'leaflet_map.png';
  //     // link.click();
  //   });
  // };

  return (
    <>
      {strategy && (
        <FeatureGroup>
          <QuoteMarker
            lng={Number(pointCoords?.lng)}
            lat={Number(pointCoords?.lat)}
            label={pointCoords?.label}
            position={true}
          >
            <QuotePolyline
              positions={pathSelected?.polylineCoords}
              id={strategy?.id}
              power={strategy?.power}
              label={strategy?.id}
              lng={strategy?.pointCoords?.lng}
              lat={strategy?.pointCoords?.lat}
              point={strategy.point}
              targetType={strategy.targetType}
            >
              <QuoteMarker
                label={strategy?.pointCoords.label}
                lng={strategy?.pointCoords.lng}
                lat={strategy?.pointCoords.lat}
                id={strategy?.id}
                position={false}
                point={strategy.point}
                targetType={strategy.targetType}
                discarded={strategy.discarded}
              />
            </QuotePolyline>
          </QuoteMarker>
        </FeatureGroup>
      )}
    </>
  );
};

export default MapScreenshotChildProps;
