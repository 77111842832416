/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type IFilters } from 'models/Filters';
import { api } from './api';
// This function is temporary, to simulate that the backend may take eome time to respond
const wait = async (ms: number): Promise<number> => {
  return await new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const getStrategies = async (): Promise<any> => {
  return await api.get(`/strategies`, {
    params: {
      power: 1200,
      clientType: 'Producteur',
      lng: 1.89143,
      lat: 48.16642,
    },
  });
};

export const postStrategies = async (filters: IFilters): Promise<any> => {
  const result = await api.post(`/strategies`, filters);
  return result;
  // return await Promise.resolve(strategies);
};
