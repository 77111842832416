export const MAP_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const API_ADDRESS_URL = 'https://api-adresse.data.gouv.fr/search/';
export const API_ADDRESS_URL_REVERSE =
  'https://api-adresse.data.gouv.fr/reverse/';

export const VALID_POST_TYPE = ['Aérien', 'PSEM', 'PIM'];
export const VALID_NBR_BUS_BAR = ['1', '2', '3'];

export const QUANTITIES = [
  { name: 'Opération 1', quantity: 0, unit_price: 0 },
  { name: 'Opération 2', quantity: 0, unit_price: 0 },
  { name: 'Opération 3', quantity: 0, unit_price: 0 },
  { name: 'Opération 4', quantity: 0, unit_price: 0 },
  { name: 'Opération 5', quantity: 0, unit_price: 0 },
];

export const AirlineStrategyCableColor: Record<string, string> = {
  '1': '#e6e600',
  '2': '#b4d79e',
  '3': '#b0b0b0',
  '4': '#e8beff',
  '5': '#ffd37f',
  '6': '#bee8ff',
  '7': '#cd8966',
  '8': '#bed2ff',
  '9': '#df73ff',
  '10': '#9eaad7',
  '11': '#00a9e6',
  '12': '#ff8000',
  '13': '#f2304d',
  '14': '#a5f57a',
  '15': '#ff7f7f',
  '16': '#a8a800',
};
