import L from 'leaflet';
import React from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { MAP_URL } from 'utils/constants';
import { type IPointCoords, type IStrategyQuote } from 'models/Quote';
import { Loading } from 'shared/Loading';
import { MapQuoteTraceChild } from './MapQuoteTraceChild';

interface QuoteStrategyProps {
  strategy: IStrategyQuote;
  isLoading: boolean;
  pathSelected: string;
  pointCoords: IPointCoords;
}

export const MapQuoteTrace: React.FC<QuoteStrategyProps> = ({
  strategy,
  isLoading,
  pathSelected,
  pointCoords,
}): React.ReactElement => {
  const center = L.latLng(48.8588897, 2.320041);
  const zoom = 17;

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MapContainer
            center={center}
            zoom={zoom}
            scrollWheelZoom={true}
            minZoom={7}
            maxZoom={18}
            zoomControl={false}
            dragging={true}
            doubleClickZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url={MAP_URL}
            />
            <ZoomControl position="bottomleft" />
            {strategy.pointCoords?.lng !== 0 &&
              strategy.pointCoords?.lat !== 0 && (
                <div id="collapse-carte">
                  <MapQuoteTraceChild
                    pointCoords={pointCoords}
                    strategy={strategy}
                    isLoading={isLoading}
                    pathSelected={pathSelected}
                  />
                </div>
              )}
          </MapContainer>
        </>
      )}
    </>
  );
};

export default MapQuoteTrace;
