import React from 'react';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { type IQuote } from 'models/Quote';

interface QuoteProps {
  detailsQuote?: IQuote;
}

const StyledPopover = styled(Popover)`
  .popover-body {
    padding: 0.5rem;
  }
`;

const StyledDiv = styled.div`
  &.contact {
    position: absolute;
    bottom: 0.3rem;
    right: 0;

    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
      color: #000000;
      background-color: transparent;
      border-color: transparent;
    }
  }
`;

const InfosContact: React.FC<QuoteProps> = ({
  detailsQuote,
}): React.ReactElement => {
  const { t } = useTranslation();

  const popoverContact = (
    <StyledPopover id="popover-basic">
      <Popover.Header as="h3">
        {t('quote.details.infos_contact.title')}
      </Popover.Header>
      <Popover.Body>
        <Row className="mb-2">
          {detailsQuote?.contactName !== '' && detailsQuote?.email !== '' && (
            <Col md={12}>
              <b className="title-primary">
                {t('quote.details.infos_contact.contact')}
              </b>
            </Col>
          )}

          {detailsQuote?.contactName !== '' && (
            <Col md={12}>
              <b className="me-2">{t('quote.details.infos_contact.name')}</b>
              {detailsQuote?.contactName}
            </Col>
          )}

          {detailsQuote?.email !== '' && (
            <Col md={12}>
              <b className="me-2">{t('quote.details.infos_contact.email')}</b>
              {detailsQuote?.email}
            </Col>
          )}

          {detailsQuote?.phone !== '' && (
            <Col md={12}>
              <b className="me-2">{t('quote.details.infos_contact.phone')}</b>{' '}
              {detailsQuote?.phone}
            </Col>
          )}
        </Row>
        <Row className="mb-2">
          <Col md={12}>
            <b className="title-primary">
              {t('quote.details.infos_contact.company')}
            </b>
          </Col>
          {detailsQuote?.companyName !== '' && (
            <Col md={12}>
              <b className="me-2">
                {t('quote.details.infos_contact.company_name')}
              </b>
              {detailsQuote?.companyName}
            </Col>
          )}

          {detailsQuote?.siret !== '' && (
            <Col md={12}>
              <b className="me-2">{t('quote.details.infos_contact.siret')} </b>
              {detailsQuote?.siret}
            </Col>
          )}
        </Row>
      </Popover.Body>
    </StyledPopover>
  );

  return (
    <StyledDiv className="contact">
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popoverContact}
        rootClose
      >
        <Button variant="link">
          <i className="icon icon-person me-1"></i>
        </Button>
      </OverlayTrigger>
    </StyledDiv>
  );
};

export default InfosContact;
