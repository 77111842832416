/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import { Accordion, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { type AlternativePaths, type IStrategyQuote } from 'models/Quote';
import ChartTrace from './ChartTrace';
import TechnicalCharacteristics from './TechnicalCharacteristics';
import { useTranslation } from 'react-i18next';
import { getdistance } from 'utils';

interface QuoteDescriptionProps {
  quoteId: string;
  quoteUid: string;
  idStrategy: string;
  pathSelected: AlternativePaths;
  detailsStrategy: IStrategyQuote;
}

const DetailsTrace: React.FC<QuoteDescriptionProps> = ({
  quoteId,
  idStrategy,
  quoteUid,
  detailsStrategy,
  pathSelected,
}): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Accordion defaultActiveKey="0" alwaysOpen flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="w-100 d-flex justify-content-between align-items-center mb-3">
              <div className="h3 d-flex justify-content-between align-items-center pt-0">
                <>
                  {t('quote.details.details_trace.title')}
                  {detailsStrategy !== undefined &&
                    detailsStrategy?.alternativePaths.length > 1 && (
                      <a
                        className="btn-link border mx-3"
                        onClick={() => {
                          navigate(
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            `/quote/${quoteId}/strategy/${idStrategy}/traces`,
                            {
                              state: { quoteUid },
                            },
                          );
                        }}
                      >
                        <i className="icon icon-alt_route"></i>
                      </a>
                    )}
                </>
              </div>
              <Badge bg="primary">
                {`${getdistance(pathSelected?.distance)} ${t(
                  'quote.details.details_trace.total',
                )}`}
              </Badge>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {pathSelected != null && (
              <ChartTrace
                alternativePaths={pathSelected}
                targetType={detailsStrategy.targetType ?? ''}
                displayInfos={true}
              />
            )}

            {/* <div className="d-flex justify-content-start align-items-center mt-2">
              <span className="icon icon-uniE906 icon-obstacle"></span>{' '}
              {t('quote.details.details_trace.obstacles')}
            </div> */}

            {/* Technical Characteristics */}
            {pathSelected && (
              <TechnicalCharacteristics
                pathSelected={pathSelected}
                posteType={detailsStrategy.poste_type}
                nbrBusbar={detailsStrategy.nbr_busbar}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default DetailsTrace;
