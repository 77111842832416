import React, { useEffect } from 'react';
import L, { type PointExpression } from 'leaflet';
import { Marker, Popup, useMap } from 'react-leaflet';
import { PointPopup } from 'shared/PointPopup';
import ImgMarker from 'images/marker.svg';

interface QuoteMarkerProps {
  children?: React.ReactNode;
  lat: number;
  lng: number;
  id?: string;
  label?: string;
  position?: boolean;
  isLoading?: boolean;
  point?: any;
  targetType?: string;
  discarded?: boolean;
}

const QuoteMarker: React.FC<QuoteMarkerProps> = ({
  lng,
  lat,
  id,
  label,
  position,
  isLoading,
  point,
  targetType,
  discarded,
}: QuoteMarkerProps): React.ReactElement => {
  const map = useMap();
  useEffect(() => {
    if (isLoading === false) {
      map?.invalidateSize();

      // Disable dragging
      map.dragging.disable();

      // Move map to center
      map.setView([lat, lng], 16).getCenter();

      // Re-enable dragging
      map.dragging.enable();
    }
  }, [lat, lng]);

  const IconMarker = new L.Icon({
    iconUrl: ImgMarker,
    iconAnchor: [13, 32],
    popupAnchor: [10, -20],
    iconSize: [30, 35],
  });

  const IconMarkerHtml = (id: string, discarded: boolean): any => {
    return `<div class="svg-icon-marker ${
      discarded ? 'discarded' : ''
    }">${id}</div>`;
  };

  // Create a custom DivIcon with event listeners
  const iconOptions: L.DivIconOptions = {
    className: 'custom-div-icon-marker',
    html:
      id != null && discarded !== undefined && IconMarkerHtml(id, discarded),
    iconAnchor: [3, 3],
    iconSize: [34, 34],
  };

  const customDivIcon = position === true ? IconMarker : L.divIcon(iconOptions);
  const popupOptions: PointExpression = position === true ? [-6, 10] : [-10, 0];

  return (
    <Marker
      position={[lat, lng]}
      icon={customDivIcon}
      draggable={false}
      eventHandlers={{
        mouseover: (e: L.LeafletMouseEvent) => e.target.openPopup(),
        mouseout: (e: L.LeafletMouseEvent) => e.target.closePopup(),
      }}
    >
      <Popup closeButton={false} offset={popupOptions}>
        <PointPopup
          lat={lat}
          lng={lng}
          id={id}
          point={point}
          targetType={targetType}
        />
      </Popup>
    </Marker>
  );
};

export default QuoteMarker;
