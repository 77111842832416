import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled.div`
  width: 86%;
  margin: 2rem auto;
  text-align: center;
  .list-group {
    --bs-list-group-border-color: transparent;
    --bs-list-group-border-width: 0;
    .list-group-item {
      padding: 4px;
      display: flex;
      align-items: center;
      color: #000;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      .box {
        width: 14px;
        height: 14px;
        margin-right: 0.5em;
        display: inline-block;
        &.hot {
          background-color: rgba(244, 127, 127, 1);
        }
        &.cold {
          background-color: rgba(127, 201, 255, 1);
        }
        &.inter {
          background-color: rgba(156, 200, 155, 1);
        }
        &.very-urban {
          background-color: rgba(59, 0, 92, 1);
        }
        &.urban {
          background-color: rgba(94, 46, 116, 1);
        }
        &.semi-urban {
          background-color: rgba(148, 121, 160, 1);
        }
        &.rural {
          background-color: rgba(199, 193, 202, 1);
        }
      }
    }
  }
`;

const InfosTrace = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              {t('quote.details.infos_trace.climate')}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="box hot"></span>
              {t('quote.details.infos_trace.hot')}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="box cold"></span>
              {t('quote.details.infos_trace.cold')}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="box inter"></span>
              {t('quote.details.infos_trace.inter')}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              {t('quote.details.infos_trace.population')}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="box very-urban"></span>
              {t('quote.details.infos_trace.very-urban')}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="box urban"></span>
              {t('quote.details.infos_trace.urban')}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="box semi-urban"></span>
              {t('quote.details.infos_trace.semi-urban')}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="box rural"></span>
              {t('quote.details.infos_trace.rural')}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        {/* <Col>
          <ListGroup>
            <ListGroup.Item>
              <span className="icon icon-uniE906 icon-obstacle"></span>{' '}
              {t('quote.details.infos_trace.obstacles')}
            </ListGroup.Item>
          </ListGroup>
        </Col> */}
      </Row>
    </StyledDiv>
  );
};

export default InfosTrace;
