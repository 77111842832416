import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { type AlternativePaths } from 'models/Quote';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'shared/ProgressBar';

const StyledTrace = styled.div`
  position: relative;
  margin: 0;
  z-index: 0;
`;

interface TraceProps {
  alternativePaths: AlternativePaths;
  displayInfos?: boolean;
  targetType: string;
}

const ChartTrace: React.FC<TraceProps> = ({
  alternativePaths,
  displayInfos,
  targetType,
}): JSX.Element => {
  const { t } = useTranslation();

  const climateInfoExists =
    alternativePaths.pathInfo?.climate !== undefined &&
    alternativePaths.pathInfo?.climate.length > 0;
  const populationInfoExists =
    alternativePaths.pathInfo?.population !== undefined &&
    alternativePaths.pathInfo?.population.length > 0;

  return (
    <Row className="mb-2">
      <Col>
        <StyledTrace className="trace">
          {climateInfoExists && (
            <ProgressBar
              traces={alternativePaths.pathInfo?.climate}
              zone={targetType === 'SUBSTATION' ? 'climate_ls' : 'climate_la'}
              title={t('quote.details.strategy_quote.chart_trace.climate')}
              displayInfos={displayInfos}
            />
          )}

          {populationInfoExists && (
            <ProgressBar
              traces={alternativePaths.pathInfo?.population}
              zone={'population'}
              title={t('quote.details.strategy_quote.chart_trace.population')}
              displayInfos={displayInfos}
            />
          )}
        </StyledTrace>
      </Col>
    </Row>
  );
};

export default ChartTrace;
