import React from 'react';
import { type AlternativePaths } from 'models/Quote';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  margin-top: 2rem;
  span {
    font-family: Nunito Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

    @media (max-width: 575.98px) {
      font-size: 0.8rem;
    }
  }

  .accordion-item .accordion-body h5,
  .accordion-item .accordion-header h5 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
  }
`;

interface InfosProps {
  pathSelected: AlternativePaths;
  posteType?: string;
  nbrBusbar?: string;
}

const TechnicalCharacteristics: React.FC<InfosProps> = ({
  pathSelected,
  posteType,
  nbrBusbar,
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <Row className="mt-4 border-top pt-2">
        <Col md="12" xs="12">
          <h3>{t('quote.details.technical_characteristics.title')}</h3>
        </Col>
        <Col md="6" xs="6">
          <span>{t('quote.details.technical_characteristics.type_cable')}</span>
        </Col>
        <Col md="6" xs="6" className="text-right">
          <h5>
            {pathSelected?.cable?.type}
            {` ${
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              pathSelected?.cable?.double
                ? '(' +
                  t('quote.details.technical_characteristics.double') +
                  ')'
                : ''
            }`}
          </h5>
        </Col>
        {posteType !== null && posteType !== undefined && (
          <>
            <Col md="6" xs="6">
              <span>
                {t('quote.details.technical_characteristics.type_poste')}
              </span>
            </Col>
            <Col md="6" xs="6" className="text-right">
              <h5>{posteType}</h5>
            </Col>
          </>
        )}
        {nbrBusbar !== null && nbrBusbar !== undefined && (
          <>
            <Col md="6" xs="6">
              <span>
                {t('quote.details.technical_characteristics.nbr_bus_bar')}
              </span>
            </Col>
            <Col md="6" xs="6" className="text-right">
              <h5>{nbrBusbar}</h5>
            </Col>
          </>
        )}
      </Row>
    </StyledContainer>
  );
};

export default TechnicalCharacteristics;
