/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Badge, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { Icon } from 'shared/Icon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InfosTrace from './InfosTrace';
import { TraceTypeLabels } from 'enums';
import QuoteContext from 'context/QuoteContext';
import { useTranslation } from 'react-i18next';

import {
  type IQuotePost,
  type AlternativePaths,
  type IQuote,
  type IStrategyQuote,
  type IPointCoords,
} from 'models/Quote';
import { type QuoteContextType } from 'types';
import MapQuoteTrace from '../map/MapQuoteTrace';
import ChartTrace from './ChartTrace';
import { getQuoteList, setQuoteStrategy } from 'services/quotes';
import { Loading } from 'shared/Loading';
import { getCostValue, getdistance } from 'utils';

const StyledContainer = styled(Container)`
  .sticky-header {
    height: 4rem;
  }
  .sticky-map {
    padding-top: 0;
    height: calc(100vh - 4rem);
  }
  .btn-link {
    --bs-btn-padding-y: 2px;
    --bs-btn-padding-x: 2px;
    &:hover,
    &.active {
      background-color: transparent;
      border-color: transparent;
      color: rgba(0, 169, 227, 1);
    }

    &.border {
      border-radius: 2px;
      border: 1px solid rgba(0, 127, 182, 1) !important;
    }
  }
  }

  .quote {
    .quote-details {
      padding: 0.3rem;
      height: 94vh;
      overflow-y: auto;
     }
    .trace-details {
      h3 {
        color: #000;
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px; /* 140% */
        text-transform: uppercase;
      }
    }
   .progress-trace {
    width: 98%;
   }
   
  }
`;

const StyledRow = styled(Row)`
  border-radius: 7px;
  border: 1px solid #bfbfbf;
  margin-bottom: 0.8rem;
  cursor: pointer;

  &.active,
  &:hover {
    border: 2px solid #009cdf;
  }

  .bar {
    width: 98%;
  }
  .badge {
    &.bg-primary {
      border-radius: 5px;
      background-color: rgba(0, 169, 227, 0.1) !important;
      color: #000;
      text-align: center;
      font-family: Nunito Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      width: 82%;
    }
  }

  .form-check {
    .form-check-label {
      color: #000;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .label {
        display: flex;
        width: 25px;
        height: 25px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid rgba(0, 169, 227, 1);
        color: #009cdf;
        text-align: center;
        font-family: Inter;
        font-size: 12.047px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 5px;
      }
    }
  }
`;

const UpdateTrace: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { quoteId, idStrategy } = useParams<{
    quoteId: string;
    idStrategy: string;
  }>();

  const locationData = useLocation();
  const quoteUid = locationData?.state?.quoteUid;
  if (!quoteUid) navigate('/quote');

  const { quoteContext, setQuoteContext } =
    useContext<QuoteContextType>(QuoteContext);

  const {
    quoteContext: { data },
  } = useContext<QuoteContextType>(QuoteContext);

  const [tableItem, setItems] = useState<IQuote[]>([]);
  const [detailsQuote, setDetailsQuote] = useState<IQuote>();
  const [detailsStrategy, setDetailsStrategy] = useState<IStrategyQuote>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [quotePost, setQuotePost] = useState<IQuotePost>({
    selectedPath: '',
  });

  const getPathSelected = (detailsStrategy: IStrategyQuote) => {
    // SELECTED ALTERNATIVE PATH
    const path = detailsStrategy?.alternativePaths.find(
      (item: AlternativePaths) => item.uid == detailsStrategy?.selectedPath,
    );
    setQuotePost({ ...quotePost, selectedPath: path?.uid });
  };

  const getStrategy = (quote: IQuote, idStrategy: string): void => {
    const strategy = quote.strategies.filter(
      (item: IStrategyQuote) => item.id == idStrategy,
    )[0];
    setDetailsStrategy(strategy);
    getPathSelected(strategy);
  };

  const getQuote = (data: IQuote[], quoteId: string, idStrategy: string) => {
    const quote = data.find((item: IQuote) => item.id === quoteId);
    setDetailsQuote(quote);

    quote && getStrategy(quote, idStrategy);
  };

  const getStrategyQuote = (quoteId: string, idStrategy: string) => {
    setLoading(true);

    if (data.length > 0) {
      setItems(data);
      getQuote(data, quoteId, idStrategy);
      setLoading(false);
    } else {
      void getQuoteList().then(({ data, status }) => {
        setQuoteContext({
          ...quoteContext,
          data,
          isLoading: false,
        });
        setItems(data);
        getQuote(data, quoteId, idStrategy);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    quoteId && idStrategy && getStrategyQuote(quoteId, idStrategy);
  }, []);

  const handleCheckboxChange = (currentUid: string) => {
    if (detailsStrategy !== undefined && detailsQuote !== undefined) {
      setLoading(true);

      if (quotePost.selectedPath !== '') {
        void setQuoteStrategy(detailsQuote.uid, detailsStrategy.uid, {
          selectedPath: currentUid,
        }).then((response: any) => {
          if (response.success) {
            setQuotePost({ ...quotePost, selectedPath: currentUid });

            if (quoteId && idStrategy) {
              updateQuoteByStrategy(quoteId, idStrategy, currentUid);

              setLoading(false);
            }
          } else {
            console.log(response);
            setLoading(false);
          }
        });
      }
    }
  };

  // update context after run API
  const updateQuoteByStrategy = (
    quoteId: string,
    idStrategy: string,
    currentUid: string,
  ) => {
    const newContext = {
      ...quoteContext,
      data,
    };

    const indexQuote = newContext.data.findIndex(
      (quote) => quote.id == quoteId,
    );
    const indexStrategy = newContext.data[indexQuote].strategies.findIndex(
      (strategy) => strategy.id == idStrategy,
    );
    if (currentUid) {
      newContext.data[indexQuote].strategies[indexStrategy] = {
        ...newContext.data[indexQuote].strategies[indexStrategy],
        selectedPath: currentUid,
      };

      setQuoteContext(newContext);
    }
  };

  const pointCoords: IPointCoords = {
    lat: Number(detailsQuote?.lat),
    lng: Number(detailsQuote?.long),
    label: detailsQuote?.address,
  };

  return (
    <>
      {tableItem.length > 0 && !isLoading ? (
        <StyledContainer fluid>
          <Row className={'quote'}>
            <Col md="6" lg="6" xxl="5" className="quote-details">
              <Form className="p-3">
                <Row className="trace-details mt-2">
                  <Col md="6">
                    <h3>
                      {t('quote.details.strategy_quote.update_trace.title')}
                    </h3>
                  </Col>
                  <Col md="6" className="d-flex justify-content-end">
                    <Icon
                      iconName="XLg"
                      color="rgba(45, 38, 75, 1)"
                      className="back-icon"
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        navigate(`/quote/${quoteId}/strategy/${idStrategy}`, {
                          state: { quoteUid },
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    {detailsStrategy !== undefined &&
                    detailsStrategy?.alternativePaths.length > 0 ? (
                      <>
                        {detailsStrategy.alternativePaths.map(
                          (trace, index) => {
                            const customLabel = `TRACE${Number(index + 1)}`;

                            return (
                              <StyledRow
                                key={index}
                                onClick={() => {
                                  handleCheckboxChange(trace.uid);
                                }}
                                id={trace.uid}
                              >
                                <Col
                                  md="3"
                                  className="d-flex align-items-start px-2 flex-column justify-content-center"
                                >
                                  <Form.Check
                                    type={`radio`}
                                    id={`check-radio-${index}`}
                                  >
                                    <Form.Check.Input
                                      type={`radio`}
                                      isValid
                                      name="trace"
                                      id={trace.uid}
                                      checked={
                                        quotePost.selectedPath === trace.uid
                                      }
                                      onChange={() => {
                                        handleCheckboxChange(trace.uid);
                                      }}
                                    />
                                    <Form.Check.Label>
                                      &nbsp;
                                      {t(
                                        'quote.details.strategy_quote.update_trace.trace',
                                      )}
                                      <span className="label">
                                        {
                                          TraceTypeLabels[
                                            customLabel as keyof typeof TraceTypeLabels
                                          ]
                                        }
                                      </span>
                                    </Form.Check.Label>
                                  </Form.Check>
                                  <Badge bg="primary" className="mb-2 mx-2">
                                    <div>{getdistance(trace.distance)}</div>
                                    <div>
                                      {t(
                                        'quote.details.strategy_quote.update_trace.total',
                                      )}
                                    </div>
                                  </Badge>
                                  <Badge bg="primary" className="mx-2">
                                    <div>{getCostValue(trace.cost)}</div>
                                    <div>
                                      {t(
                                        'quote.details.strategy_quote.update_trace.cost_estimated',
                                      )}
                                    </div>
                                  </Badge>
                                </Col>
                                <Col
                                  md="9"
                                  className={`trace-${index}`}
                                  onClick={() => {
                                    handleCheckboxChange(trace.uid);
                                  }}
                                >
                                  <ChartTrace
                                    key={index}
                                    alternativePaths={trace}
                                    displayInfos={false}
                                    targetType={
                                      detailsStrategy?.targetType ?? ''
                                    }
                                  />
                                </Col>
                              </StyledRow>
                            );
                          },
                        )}
                      </>
                    ) : (
                      <Alert variant={'warning'}>
                        {t(
                          'quote.details.strategy_quote.update_trace.errors.empty',
                        )}
                      </Alert>
                    )}

                    <InfosTrace />
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md="6" lg="6" xxl="7" className="map">
              {detailsQuote != null &&
                detailsStrategy != null &&
                quotePost?.selectedPath !== undefined && (
                  <MapQuoteTrace
                    isLoading={isLoading}
                    strategy={detailsStrategy}
                    pathSelected={quotePost?.selectedPath}
                    pointCoords={pointCoords}
                  />
                )}
            </Col>
          </Row>
        </StyledContainer>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default UpdateTrace;
