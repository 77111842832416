import React from 'react';
import { type QuoteContextType } from 'types';
const QuoteContext = React.createContext<QuoteContextType>({
  quoteContext: {
    data: [],
    isLoading: true,
    totalSeche: 0,
  },
  setQuoteContext: () => {
    // do nothing.
  },
});
QuoteContext.displayName = 'Quote Context';
export default QuoteContext;
