/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getCostValue } from 'utils';

interface BadgeProps {
  targetType: string;
  costsDetails: any;
}

const StyledDiv = styled.div`
  .estimated-cost {
    .row {
      text-align: left;
      font-weight: 300;
    }

    .unit {
      font-size: 0.9rem;
      font-weight: 300;
      margin-top: -10px;
    }
    .border-right {
      margin: auto 1rem;
      height: 1.8rem;
      width: 0.05rem;
      display: block;
      background: #949494;
      position: relative;
      top: -0.5rem;
      }
    }
  }
`;

const BadgeEstimatedCost: React.FC<BadgeProps> = ({
  targetType,
  costsDetails,
}): React.ReactElement => {
  const { t } = useTranslation();

  const [estimadedCost, setEstimadedCost] = useState({
    min: 0,
    max: 0,
  });

  const [estimadedDisplay, setEstimadedDisplay] = useState(false);

  const getEstimadedCost = (targetType: string, costsDetails: any): void => {
    if (targetType === 'SUBSTATION' && costsDetails.isCostInitialized) {
      setEstimadedCost({
        ...estimadedCost,
        min: costsDetails.total.p50,
        max: costsDetails.total.p70,
      });
      setEstimadedDisplay(false);
    }
    if (targetType === 'SUBSTATION' && !costsDetails.isCostInitialized) {
      setEstimadedCost({
        ...estimadedCost,
        min: costsDetails.total.min,
        max: costsDetails.total.max,
      });
      setEstimadedDisplay(true);
    }
    if (targetType === 'PYLON') {
      setEstimadedCost({
        ...estimadedCost,
        min: costsDetails.total.p50,
        max: costsDetails.total.p70,
      });
      setEstimadedDisplay(false);
    }
  };

  useEffect(() => {
    getEstimadedCost(targetType, costsDetails);
  }, [targetType, costsDetails]);

  return (
    <StyledDiv className="w-100 d-flex justify-content-between align-items-center mb-3">
      <h3 className="d-flex justify-content-between align-items-center">
        {t('quote.details.strategy_quote.details_costs.estimated_costs.title')}
      </h3>
      <Badge bg="primary" className="estimated-cost">
        <Row className={`m-0`}>
          <Col>
            <Row className={`flex-column cost m-0`}>
              {estimadedDisplay ? (
                <Col>
                  <Row className="fst-italic unit">
                    <Col style={{ width: '60%' }}>
                      {t(
                        'quote.details.strategy_quote.details_costs.estimated_costs.min',
                      )}
                    </Col>
                    <Col style={{ width: '10%', maxWidth: '20%' }}></Col>
                    <Col style={{ width: '30%' }}>
                      {t(
                        'quote.details.strategy_quote.details_costs.estimated_costs.max',
                      )}
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col>
                  <Row className="fst-italic unit">
                    <Col style={{ width: '60%' }}>
                      {t(
                        'quote.details.strategy_quote.details_costs.estimated_costs.p50',
                      )}
                    </Col>
                    <Col style={{ width: '10%', maxWidth: '20%' }}></Col>
                    <Col style={{ width: '30%' }}>
                      {t(
                        'quote.details.strategy_quote.details_costs.estimated_costs.p70',
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
              <Col className="estimaded">
                <Row>
                  <Col className={`fw-bolder`}>
                    <strong>{getCostValue(estimadedCost.min)}</strong>
                  </Col>

                  {estimadedDisplay ? (
                    <Col>
                      <span className="icon-arrow icon-equiv-arrow"></span>
                    </Col>
                  ) : (
                    <Col>
                      <div className={`border-right`}></div>
                    </Col>
                  )}

                  <Col className="fw-bolder margin">
                    <strong>{getCostValue(estimadedCost.max)}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Badge>
    </StyledDiv>
  );
};

export default BadgeEstimatedCost;
