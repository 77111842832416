/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Nav,
  NavLink,
  Row,
  Spinner,
} from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { type UserForm } from 'models/User';
import { loginUser, logoutUser, userIsLogged } from 'services/user';
import { useNavigate } from 'react-router-dom';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'services/NotificationService';
import { Icon } from 'shared/Icon';

const StyledModal = styled(Modal)`
  font-family: Open Sans;
  font-style: normal;
  color: #212121;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
        color: #6c6c6c;
        text-decoration: none;
      }
    }
  }

  .modal-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 10.879px;
  }
  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }
`;

const LoginModal: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [formData, setFormData] = useState<UserForm>({
    username: '',
    password: '',
  });

  const openModal = (): void => {
    setIsOpen(true);
  };
  const closeModal = (): void => {
    setIsOpen(false);
  };

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const required = (value: string): boolean => {
    return value !== '';
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    // Validation
    const newErrors = {
      username: '',
      password: '',
    };

    if (formData.username !== undefined && !required(formData.username)) {
      newErrors.username = t('strategy.form.errors.required');
    }

    if (formData.password !== undefined && !required(formData.password)) {
      newErrors.password = t('strategy.form.errors.required');
    }

    setErrors(newErrors);

    // If there are no errors, you can submit the form
    if (newErrors.username === '' && newErrors.password === '') {
      // Handle form submission here
      setLoading(true);

      void loginUser(formData)
        .then((response: any) => {
          console.log('response', response);

          if (response.status === 200) {
            setLoading(false);
            closeModal();
            showSuccessNotification(t('login.success_login'));
            navigate(`/`);
          } else {
            setLoading(false);
            showErrorNotification(t('login.errors.invalide'));
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log('error', error);
          showErrorNotification(t('login.errors.500'));
        });
    }
  };

  const handleLogout = async (e: React.FormEvent) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    logoutUser();

    void logoutUser()
      .then((response: any) => {
        console.log('response', response);

        if (response.status === 200) {
          showSuccessNotification(t('login.success_logout'));
          navigate(`/`);
        } else {
          showErrorNotification(t('login.errors.invalide'));
        }
      })
      .catch((error) => {
        console.log('error', error);
        showErrorNotification(t('login.errors.500'));
      });
  };

  return (
    <>
      <Nav className="me-2">
        {!userIsLogged() ? (
          <NavLink onClick={openModal}>
            <Icon
              iconName="BoxArrowRight"
              color="rgba(0, 0, 0, 1)"
              className={`back-icon me-1`}
            />{' '}
            <span>{t('login.btn_login')}</span>
          </NavLink>
        ) : (
          <NavLink onClick={handleLogout}>
            <Icon
              iconName="BoxArrowLeft"
              color="rgba(0, 0, 0, 1)"
              className={`back-icon me-1`}
            />{' '}
            <span>{t('login.btn_logout')}</span>
          </NavLink>
        )}
      </Nav>

      <StyledModal
        show={modalIsOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size={'sm'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('login.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-2" controlId="username">
                <Form.Label>
                  {t('login.username')}
                  <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    handleInputChange(e);
                  }}
                  isInvalid={!(errors.username === '')}
                  bsPrefix="form-control-rak"
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-2" controlId="password">
                <Form.Label>
                  {t('login.password')} <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  bsPrefix="form-control-rak"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    handleInputChange(e);
                  }}
                  isInvalid={!(errors.password === '')}
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col
              sm={12}
              className="d-flex align-items-center justify-content-end"
            >
              <Button
                className="btn-rak-link"
                variant="link"
                onClick={closeModal}
              >
                {t('quote.details.delete_quote.button.close')}
              </Button>
              <Button
                variant="primary"
                onClick={handleLogin}
                className="btn-rak-primary d-flex align-items-center"
              >
                {t('login.btn')}
                {isLoading && (
                  <Spinner className="spinner" animation="border" size="sm" />
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default LoginModal;
