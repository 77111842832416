/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  InputGroup,
  Alert,
  Pagination,
} from 'react-bootstrap';
import styled from 'styled-components';
import { redirect, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QuoteContext from 'context/QuoteContext';
import { type QuoteContextType } from 'types';
import { type IStrategyQuote, type IQuote } from 'models/Quote';
import { getQuoteList } from 'services/quotes';
import { Loading } from 'shared/Loading';
import { formattedDate } from 'utils';
import { ClientTypeLabels } from 'enums';
import { showErrorNotification } from 'services/NotificationService';

const StyledContainer = styled(Container)`
    margin-top: 0;
    height: calc(100vh - 10rem);

    .container-md {
    }
    .table-bordered > :not(caption) > * > * {
      border-width: 0 0;
    }
    .table {
      tr {
        cursor: pointer;
      }
      }
    }
    .form-control-rak {
      width: 100%;
    }

    .icon {
      float: right;
      position: relative;
      top: 5px;
      font-size: 1.4rem;
      right: -3px;
    }

    .search {
      width: 100%;
      position: relative;
      display: inline-block;
      .search-input {
        width: 100%;
        padding: 10px;
        font-size: 13px;
        color: var(--rak-palette-textSearch);
      }
      .search-icon {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        font-size: 1.4rem;
      }
    }
    .icon-Frame-134 {
      font-size: 1.2rem;
      position: relative;
      top: -1px;
    }

    table {
      font-family: Inter;
      font-weight: 400;
    }
    .title {      
      font-size: 0.8rem;
      letter-spacing: 0em;
      text-align: left;
    }
    .items{
      color: rgba(118, 118, 118, 1);
      font-size: 10px;
      display:list-item;

      span {
        margin-right: 0.5rem;
        float: left;

        &:first-child {
          &::before{
            content: "";
            display: none;
         }
        }

        &::before{
          content: "";
          display: inline-block;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 7.5px;
          background-color: rgb(118, 118, 118);
          position: relative;
          top: -1px;
          left: -3px;
       }
      }
    }
`;

const StyledPagination = styled(Pagination)`
  justify-content: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  .page-link {
    color: rgba(0, 127, 182, 1);
  }
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    font-size: 1rem;
  }

  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: rgba(0, 127, 182, 1);
    border-color: rgba(0, 127, 182, 1);
  }
`;

const QuoteList: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tableItem, setItems] = useState<IQuote[]>([]);
  const [tableFiltredItem, setFiltredItems] = useState<IQuote[]>([]);

  const { quoteContext, setQuoteContext } =
    useContext<QuoteContextType>(QuoteContext);

  const [isLoading, setLoading] = useState(true);

  const sortItemsByDateDesc = (items: IQuote[]): IQuote[] => {
    const sortedItems = [...items].sort(
      (a, b) =>
        new Date(b.dateDevis).getTime() - new Date(a.dateDevis).getTime(),
    );
    return sortedItems;
  };

  //* ******************* CONTROL PAGINATION ****************************** */
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Number of items to display per page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const totalPages = Math.ceil(tableFiltredItem.length / itemsPerPage);

  const currentItems = tableFiltredItem.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getQuoteItems = () => {
    void getQuoteList()
      .then(({ data, status }) => {
        if (data.length > 0) {
          setQuoteContext({
            ...quoteContext,
            data: sortItemsByDateDesc(data),
          });
          setItems(sortItemsByDateDesc(data));
          setFiltredItems(sortItemsByDateDesc(data));
          setLoading(false);
        } else {
          setLoading(false);
          switch (status) {
            case 200:
              showErrorNotification(t('quote.list.errors.empty'));
              break;
            case 401:
              showErrorNotification(t('strategy.form.errors.401'));
              break;
            case 403:
              showErrorNotification(t('strategy.form.errors.403'));
              break;
            default:
              showErrorNotification(t('quote.list.errors.500'));
              break;
          }
          redirect('/404');
        }
      })
      .catch((error) => {
        console.log('error', error);
        showErrorNotification(t('quote.list.errors.500'));
        redirect('/404');
      });
  };

  useEffect(() => {
    return getQuoteItems();
  }, []);

  const [sortedColumn, setSortedColumn] = useState<string>(''); // Column by which to sort
  const [filter, setFilter] = useState<string>(''); // Filter string
  // const navigate = useNavigate();
  const handleSort = (columnName: string): void => {
    setSortedColumn(columnName);
    setItems((prevData: any) =>
      [...prevData].sort((a, b) => (a[columnName] > b[columnName] ? 1 : -1)),
    );
    console.log('====================================');
    console.log(sortedColumn);
    console.log('====================================');
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const filterValue = event.target.value;

    if (filterValue.length === 0) {
      getQuoteItems();
    }

    const filtred = tableItem.filter(
      (item: IQuote) =>
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        item.companyName.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.id.toLowerCase().includes(filterValue.toLowerCase()) ||
        formattedDate(item.dateDevis)
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase()),
    );

    setFilter(filterValue);
    setFiltredItems(filtred);
  };

  const countStrategies = (_strategies: IStrategyQuote[]): string => {
    return `${_strategies.length > 0 ? _strategies.length : '0'} stratégie(s)`;
  };

  const handleClick = (id: string, quoteUid: string) => {
    navigate(`/quote/${id}`, { state: { quoteUid } });
  };

  return (
    <StyledContainer fluid>
      <Row className="p-4">
        <Col md="5" className="d-flex">
          <div className="search mb-3">
            <InputGroup className={`input-group`}>
              <Form.Control
                type="text"
                bsPrefix="form-control-rak"
                placeholder={t('quote.list.placeholder')}
                value={filter}
                onChange={handleFilter}
                className="text-truncate"
              />
            </InputGroup>
            <i className="icon icon-search_black_24dp-1 search-icon"></i>
          </div>
          <span
            className="icon-sort icon text-right d-block d-sm-none"
            onClick={() => {
              handleSort('date');
            }}
          ></span>
        </Col>
        <Col md="12">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {currentItems !== undefined && currentItems.length > 0 ? (
                <>
                  <Table
                    bordered
                    hover
                    responsive="sm"
                    className="d-none d-md-block d-lg-block"
                  >
                    <thead>
                      <tr>
                        <th>{t('quote.list.id')}</th>
                        <th>{t('quote.list.address')}</th>
                        <th>{t('quote.list.nbr_strategy')}</th>
                        <th>{t('quote.list.name')}</th>
                        <th>{t('quote.list.type')}</th>
                        <th>
                          {t('quote.list.date')}{' '}
                          <span
                            className="icon-sort icon text-right"
                            onClick={() => {
                              handleSort('date');
                            }}
                          ></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item: IQuote) => (
                        <tr
                          key={item.id}
                          onClick={() => handleClick(item.id, item.uid)}
                        >
                          <td width={'10%'}>{item.id}</td>
                          <td width={'35%'}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${
                                  item.address
                                    ? `${item.address}`
                                    : `${t('quote.details.lat')} ${
                                        item.lat
                                      }   ${t('quote.details.long')}${
                                        item.long
                                      }`
                                }`,
                              }}
                            ></div>
                          </td>
                          <td>
                            {item !== undefined &&
                              item.strategies.length > 0 &&
                              countStrategies(item.strategies)}
                          </td>
                          <td>
                            {`${item.companyName}`}{' '}
                            {`${item.siret && '(' + item.siret + ')'}`}
                          </td>
                          <td>
                            {
                              ClientTypeLabels[
                                item.clientType as keyof typeof ClientTypeLabels
                              ]
                            }
                          </td>
                          <td>{formattedDate(item.dateDevis)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Table
                    bordered
                    hover
                    responsive="sm"
                    className="d-block d-sm-none"
                  >
                    <tbody>
                      {currentItems.map((item: IQuote) => (
                        <tr
                          key={item.id}
                          onClick={() => handleClick(item.id, item.uid)}
                        >
                          <td width={'100%'}>
                            <div>
                              <span className="title">{item.id}</span>{' '}
                              <span>{formattedDate(item.dateDevis)}</span>
                            </div>
                            <div className="items">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `${
                                    item.address
                                      ? `${item.address}`
                                      : `${t('quote.details.lat')} ${
                                          item.lat
                                        }   ${t('quote.details.long')}${
                                          item.long
                                        }`
                                  }`,
                                }}
                              ></span>
                              <span>
                                {item !== undefined &&
                                  item.strategies.length > 0 &&
                                  countStrategies(item.strategies)}
                              </span>
                              <span>
                                {`${item.companyName}`}{' '}
                                {`${item.siret && '(' + item.siret + ')'}`}
                              </span>
                              <span>
                                {
                                  ClientTypeLabels[
                                    item.clientType as keyof typeof ClientTypeLabels
                                  ]
                                }
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {/* Render the pagination component */}
                  {tableFiltredItem.length > 15 && (
                    <StyledPagination>
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      {Array.from({
                        length: Math.ceil(
                          tableFiltredItem.length / itemsPerPage,
                        ),
                      }).map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={Number(currentPage) === Number(totalPages)}
                      />
                    </StyledPagination>
                  )}
                </>
              ) : (
                <Alert variant={`danger`} className="w-100 m-2">
                  {t('quote.list.errors.empty')}
                </Alert>
              )}
            </>
          )}
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default QuoteList;
