/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getPostConfig, setCostPoste } from 'services/quotes';
import styled from 'styled-components';
import RangeSlider from 'react-bootstrap-range-slider';
import {
  type Quantity,
  type ICostPost,
  type IStrategyQuote,
} from 'models/Quote';
import {
  VALID_POST_TYPE,
  VALID_NBR_BUS_BAR,
  QUANTITIES,
} from 'utils/constants';
import { Loading } from 'shared/Loading';
import { type QuoteContextType } from 'types';
import QuoteContext from 'context/QuoteContext';
import { showErrorNotification } from 'services/NotificationService';
import { getCostValueCommas } from 'utils';

interface UpdateCostsModalProps {
  detailsStrategy: IStrategyQuote;
  quoteUid: string;
  quoteId?: string;
  isOpen: boolean;
  tensionNbr: number;

  editable: any;
  onClose: () => void;
  handleUpdate: () => void;
  posteType?: string;
  nbrBusbar?: string;
  contrainte?: number;
}

const StyledModal = styled(Modal)`
  font-family: Open Sans;
  font-style: normal;
  color: #212121;

  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
    @media (max-width: 767.98px) {
      padding: 10px !important;
    }
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
        color: #6c6c6c;
        text-decoration: none;
      }

      &.btn-primary {
        border-radius: 4px;
        border: 1px solid #009cdf;
        background: #009cdf;
        color: #fff;
        text-align: center;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }

      &.btn-outline-light {
        border-radius: 4px;
        border: 1px solid #009cdf;
        background: #fff;
        color: #009cdf;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }
    }
  }

  h3 {
    color: #000;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 140% */
    text-transform: uppercase;
  }

  .modal-title {
    color: #000;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 140% */
    text-transform: uppercase;
  }

  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;

    @media (max-width: 575.98px) {
      padding: 0;
    }

    .list-group {
      @media (max-width: 575.98px) {
        font-size: 11px;
      }

      &.quantities {
        margin-top: 1rem;
        --bs-list-group-border-color: transparent;
        --bs-list-group-border-width: 0;
        .list-group-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #000;
          font-family: 'Nunito Sans', sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          .name-text {
            flex-basis: 293px;
          }
          .price-text {
            flex-basis: 65px;
          }
          .w-15 {
            width: 15%;
          }

          .w-10 {
            width: 10%;
          }

          .badge.bg-primary {
            font-size: 16px;
          }

          &.disable {
            opacity: 0.3;
          }

          @media (max-width: 575.98px) {
            font-size: 11px;
          }
        }

        .infos {
          font-family: Nunito Sans;
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: left;
          color: #009cdf;
        }
      }
    }
  }

  .form-control-sm {
    text-align: center;
  }
  .range {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

    .range-slider {
      width: 100%;
      position: relative;
      top: -2px;
    }

    input[type='range']::-webkit-slider-thumb {
      background: rgba(0, 127, 182, 1) !important;
      width: 16px;
      height: 16px;
      margin-top: -6px;
    }

    /* All the same stuff for Firefox */
    input[type='range']::-moz-range-thumb {
      background: rgba(0, 127, 182, 1) !important;
      width: 16px;
      height: 16px;
      margin-top: -6px;
    }

    /* All the same stuff for IE */
    input[type='range']::-ms-thumb {
      background: rgba(0, 127, 182, 1) !important;
      width: 16px;
      height: 16px;
      margin-top: -6px;
    }

    input[type='range'].range-slider::-webkit-slider-runnable-track {
      box-sizing: border-box;
      border: none;
      height: 4px;
      border-radius: 2px;
      background: rgba(179, 229, 249, 1);
    }

    ::-webkit-slider-thumb:active {
      background-color: #b6d4fe;
    }
  }
`;

const UpdateCostsModal: React.FC<UpdateCostsModalProps> = ({
  quoteUid,
  quoteId,
  detailsStrategy,
  isOpen,
  tensionNbr,
  contrainte,
  onClose,
  handleUpdate,
  editable,
  posteType,
  nbrBusbar,
}): React.ReactElement => {
  const { t } = useTranslation();

  const { quoteContext, setQuoteContext } =
    useContext<QuoteContextType>(QuoteContext);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [configItem, setConfigItems] = useState<string[]>([]);
  const [formData, setFormData] = useState<ICostPost>({
    costs: {
      poste_type: '',
      nbr_busbar: '',
      poste_facteur_contrainte: 8,
      quantities: QUANTITIES,
    },
  });

  useEffect(() => {
    if (posteType !== undefined && nbrBusbar !== undefined) {
      setFormData({
        costs: {
          poste_type: posteType,
          nbr_busbar: nbrBusbar,
          poste_facteur_contrainte: contrainte,
          quantities: editable.length > 0 ? editable : QUANTITIES,
        },
      });
    }
  }, []);

  const total: number = formData.costs.quantities.reduce(
    (accumulator: number, currentValue: Quantity) => {
      return (
        accumulator + currentValue.unit_price * Number(currentValue.quantity)
      );
    },
    0,
  );

  const typeWorks = (): string => {
    return ` ${t(
      'quote.details.strategy_quote.details_costs.update_costs.costs',
    )} ${detailsStrategy.targetType === 'SUBSTATION' ? 'poste' : 'ligne'}`;
  };

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setFormData({
      costs: {
        poste_type: e.target.value,
        nbr_busbar: '',
        poste_facteur_contrainte: formData.costs.poste_facteur_contrainte,
        quantities: QUANTITIES,
      },
    });
  };

  const handleChangeNbr = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setLoading(true);
    void setCostPoste(quoteUid, detailsStrategy.uid, {
      costs: {
        poste_type: formData.costs.poste_type,
        nbr_busbar: e.target.value,
        poste_facteur_contrainte: formData.costs.poste_facteur_contrainte,
        quantities: [],
      },
    })
      .then((result) => {
        setFormData({
          costs: {
            quantities: result.data.data.costsExensions.posteCosts,
            poste_type: result.data.data.costsExensions.posteType,
            nbr_busbar: result.data.data.costsExensions.nbrBusbar,
            poste_facteur_contrainte: formData.costs.poste_facteur_contrainte,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        showErrorNotification(t('quote.list.errors.500'));
        setLoading(false);
      });
  };

  const handleChangeContrainte = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    formData.costs.poste_facteur_contrainte = Number(e.target.value);
    setFormData({
      ...formData,
    });
  };

  useEffect(() => {
    if (tensionNbr !== undefined) {
      setLoading(true);
      void getPostConfig(tensionNbr)
        .then((result) => {
          setConfigItems(result);

          setLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          showErrorNotification(t('quote.list.errors.500'));
          setLoading(false);
        });
    }
  }, []);

  const userExists = (value: string, item: any): boolean => {
    return configItem.some(function (el) {
      return el[item] === value;
    });
  };

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    const cost = Number(e.target.value);
    const tmp = formData.costs.quantities;
    const quantityItemsClone = [...tmp];
    const quantityItem = { ...tmp[index] };
    quantityItem.quantity = cost;
    quantityItemsClone[index] = quantityItem;
    setFormData({
      costs: {
        ...formData.costs,
        quantities: quantityItemsClone,
      },
    });
  };

  const handleUpdateCost = (): void => {
    setLoading(true);
    void setCostPoste(quoteUid, detailsStrategy.uid, formData)
      .then((result) => {
        setFormData({
          costs: {
            poste_type: result.data.data.costsExensions.posteType,
            nbr_busbar: result.data.data.costsExensions.nbrBusbar,
            poste_facteur_contrainte: 8,
            quantities: result.data.data.costsExensions.posteCosts,
          },
        });

        setQuoteContext({
          ...quoteContext,
          totalSeche: total,
        });
        setLoading(false);
        handleUpdate();
        onClose();
      })
      .catch((error) => {
        console.log('error', error);
        showErrorNotification(t('quote.list.errors.500'));
        setLoading(false);
      });
  };

  const checkInfos =
    total === 0 ||
    formData.costs?.poste_type === '' ||
    formData.costs?.nbr_busbar === '';

  return (
    <StyledModal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{typeWorks()}</Modal.Title>
      </Modal.Header>
      {isLoading && <Loading />}
      <Modal.Body>
        <Form>
          <ListGroup className="no-border">
            <ListGroup.Item>
              <Row>
                <Col>
                  <div>
                    {t(
                      'quote.details.strategy_quote.details_costs.update_costs.poste_type',
                    )}
                  </div>
                </Col>
                <Col>
                  <Form.Group className="mb-2" controlId="poste_type">
                    <Form.Select
                      onChange={handleChangeType}
                      name="poste_type"
                      bsPrefix="form-control-rak"
                      defaultValue={formData.costs.poste_type}
                      value={formData.costs.poste_type || ''}
                    >
                      <option value={''}>...</option>
                      {VALID_POST_TYPE.map((elt) => {
                        return (
                          <option
                            key={elt}
                            value={elt}
                            disabled={!userExists(elt, 'posteType')}
                            selected={formData.costs.poste_type === elt}
                          >
                            {elt}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <div>
                    {t(
                      'quote.details.strategy_quote.details_costs.update_costs.nbr_busbar',
                    )}
                  </div>
                </Col>
                <Col>
                  <Form.Group className="mb-2" controlId="nbr_busbar">
                    <Form.Select
                      onChange={handleChangeNbr}
                      name="nbr_busbar"
                      bsPrefix="form-control-rak"
                      disabled={formData.costs?.poste_type === ''}
                      defaultValue={formData.costs.nbr_busbar}
                      value={formData.costs.nbr_busbar || ''}
                    >
                      <option value={''}>...</option>
                      {VALID_NBR_BUS_BAR.map((elt) => {
                        return (
                          <option
                            key={elt}
                            value={elt}
                            defaultValue={elt || ''}
                            disabled={!userExists(elt, 'nbBusBar')}
                            selected={formData.costs.nbr_busbar === elt}
                          >
                            {elt}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </ListGroup.Item>

            {formData.costs.poste_facteur_contrainte && (
              <ListGroup.Item>
                <Row>
                  <Col>
                    <div style={{ width: '85%', display: 'block' }}>
                      {t(
                        'quote.details.strategy_quote.details_costs.update_costs.contrainte',
                      )}
                    </div>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-2"
                      controlId="poste_facteur_contrainte"
                      as={Row}
                    >
                      <Col sm="8" xs="8" className="range">
                        <div style={{ marginRight: '0.3rem' }}>
                          {t(
                            'quote.details.strategy_quote.details_costs.update_costs.range.min',
                          )}
                        </div>
                        <RangeSlider
                          value={formData.costs.poste_facteur_contrainte}
                          onChange={handleChangeContrainte}
                          min={5}
                          max={30}
                          variant="primary"
                        />
                        <div style={{ marginLeft: '0.1rem' }}>
                          {t(
                            'quote.details.strategy_quote.details_costs.update_costs.range.max',
                          )}
                        </div>
                      </Col>
                      <Col sm="1" xs="1"></Col>
                      <Col sm="3" xs="3">
                        <Form.Control
                          value={`${formData.costs.poste_facteur_contrainte}%`}
                          size="sm"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
          </ListGroup>

          <ListGroup className="no-border quantities">
            <ListGroup.Item style={{ height: '1rem' }}>
              {checkInfos && (
                <span className="infos">
                  {t(
                    'quote.details.strategy_quote.details_costs.update_costs.infos',
                  )}
                </span>
              )}
            </ListGroup.Item>

            {formData.costs.quantities.map((item: Quantity, index: number) => {
              return (
                <ListGroup.Item
                  key={index}
                  className="d-flex justify-content-between align-items-center mx-1"
                >
                  <div className="name-text">{item.name}</div>
                  <div className="w-25 d-flex align-items-center justify-content-between">
                    <div className="price-text">
                      {getCostValueCommas(item.unit_price)}
                    </div>
                    <strong className="me-2 mx-2">X</strong>
                  </div>
                  <div className="w-10 d-flex align-items-center">
                    <Form.Control
                      size="sm"
                      type="number"
                      name={`quantity-${index}`}
                      min="0"
                      value={item.quantity || 0}
                      // defaultValue={item.quantity || 0}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleQuantityChange(e, index);
                      }}
                      disabled={formData.costs.quantities === QUANTITIES}
                      placeholder="0"
                    />
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer className="mt-2 d-flex justify-content-between align-items-center">
        <h3>{`${t(
          'quote.details.strategy_quote.details_costs.update_costs.total',
        )} ${getCostValueCommas(total)}`}</h3>
        <div className="d-flex justify-content-end">
          <Button variant="outline-light" onClick={onClose}>
            {t(
              'quote.details.strategy_quote.details_costs.update_costs.btn_cancel',
            )}
          </Button>
          <Button
            variant="primary"
            onClick={handleUpdateCost}
            className="mx-2"
            disabled={
              formData.costs?.poste_type === '' &&
              formData.costs?.nbr_busbar === ''
            }
          >
            {t(
              'quote.details.strategy_quote.details_costs.update_costs.btn_save',
            )}
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  );
};

export default UpdateCostsModal;
