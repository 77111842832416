/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import QuoteContext from 'context/QuoteContext';
import { type IQuotePost, type IStrategyQuote } from 'models/Quote';
import styled from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { setQuoteStrategy } from 'services/quotes';
import { Loading } from 'shared/Loading';
import { type QuoteContextType } from 'types';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'services/NotificationService';

const StyledRow = styled(Row)`
  .btn-rak-primary {
    display: block;
    height: 2.5rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 1.5rem;
      color: white;
    }
  }
`;

interface StrategyProps {
  strategy?: IStrategyQuote;
  quoteUid: string;
  quoteId: string;
  idStrategy: string;
  showComment: boolean;
  showCommentary: (show: boolean) => void;
}

const CommentaryStrategy: React.FC<StrategyProps> = ({
  strategy,
  quoteUid,
  quoteId,
  idStrategy,
  showComment,
  showCommentary,
}): React.ReactElement => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);

  const { quoteContext, setQuoteContext } =
    useContext<QuoteContextType>(QuoteContext);

  const {
    quoteContext: { data },
  } = useContext<QuoteContextType>(QuoteContext);

  const [quotePost, setQuotePost] = useState<IQuotePost>({
    commentary: '',
  });

  useEffect(() => {
    setQuotePost({ ...quotePost, commentary: strategy?.commentary });
  }, [strategy?.commentary]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuotePost({ ...quotePost, commentary: event.target.value });
  };

  // control form Commentary
  const handleCommentary = (): void => {
    if (strategy !== undefined) {
      setLoading(true);

      if (quotePost.commentary !== '') {
        void setQuoteStrategy(quoteUid, strategy.uid, quotePost)
          .then((response: any) => {
            if (response.success) {
              setLoading(false);
              showSuccessNotification(
                t('quote.details.strategy_quote.commentary_strategy.success'),
              );

              quoteId &&
                idStrategy &&
                updateQuoteByStrategy(quoteId, idStrategy, quotePost);

              sendshowCommentary();
            } else {
              showErrorNotification(
                t(
                  'quote.details.strategy_quote.commentary_strategy.errors.required',
                ),
              );

              setLoading(false);
            }
          })
          .catch((error) => {
            console.log('error', error);
            showErrorNotification(
              t('quote.details.strategy_quote.commentary_strategy.errors.500'),
            );
            setLoading(false);
          });
      }
    }
  };

  const sendshowCommentary = () => {
    // Call the parent's callback function to send data
    showCommentary(false);
  };

  // update context after run API
  const updateQuoteByStrategy = (
    quoteId: string,
    idStrategy: string,
    form: IQuotePost,
  ) => {
    const newContext = {
      ...quoteContext,
      data,
    };

    const indexQuote = newContext.data.findIndex(
      (quote) => quote.id == quoteId,
    );
    const indexStrategy = newContext.data[indexQuote].strategies.findIndex(
      (strategy) => strategy.id == idStrategy,
    );

    newContext.data[indexQuote].strategies[indexStrategy] = {
      ...newContext.data[indexQuote].strategies[indexStrategy],
      commentary: form.commentary,
    };

    setQuoteContext(newContext);
  };

  return (
    <>
      {isLoading && <Loading />}
      <>
        {!showComment && quotePost?.commentary !== '' && (
          <Row>
            <Col md="12" className="">
              <div className="commentary">
                <h6 className="pe-2 mb-0">
                  {t('quote.details.strategy_quote.commentary_strategy.title')}
                </h6>
                <p>{quotePost?.commentary}</p>
              </div>
            </Col>
          </Row>
        )}
        {showComment && (
          <StyledRow className="tab-comment mt-3">
            <Col md="10">
              <Form.Group className="mb-2" controlId="message">
                <Form.Label>
                  {t('quote.details.strategy_quote.commentary_strategy.title')}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="message"
                  onChange={handleChange}
                  value={quotePost.commentary}
                />
              </Form.Group>
            </Col>
            <Col
              md="2"
              className="d-flex justify-content-end align-items-center"
            >
              <Button
                className="btn-rak-primary"
                size="sm"
                disabled={quotePost.commentary === ''}
                onClick={handleCommentary}
              >
                <span className="icon icon-floppy2"></span>
              </Button>
            </Col>
          </StyledRow>
        )}
      </>
    </>
  );
};

export default CommentaryStrategy;
