import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { API_ADDRESS_URL_REVERSE } from 'utils/constants';

interface PointPopupProps {
  lat?: number;
  lng?: number;
  id?: string;
  point?: any;
  targetType?: string;
}

export const PointPopup: React.FC<PointPopupProps> = ({
  lat,
  lng,
  id,
  point,
  targetType,
}: PointPopupProps): React.ReactElement => {
  const { t } = useTranslation();

  const [address, setAddress] = useState<string>('');

  const isCoordinate = (long: number, lat: number): boolean => {
    const latMin = 42.3404785156;
    const latMax = 51.0971191406;

    const longMin = -4.7625;
    const longMax = 8.14033203125;

    const longOk = long >= longMin && long <= longMax;
    const latOk = lat >= latMin && lat <= latMax;

    return longOk && latOk;
  };

  const handleAddressByCoords = (lng: number, lat: number): void => {
    if (isCoordinate(lng, lat)) {
      fetch(`${API_ADDRESS_URL_REVERSE}?lon=${lng}&lat=${lat}`)
        .then(async (response) => await response.json())
        .then((result) => {
          if (result.features.length > 0) {
            const feature = result.features[0];

            setAddress(feature.properties.label);
          } else {
            const feature = `<b className="fw-bold">Lat:</b> ${lat
              .toString()
              .replace(
                '.',
                ',',
              )}&nbsp;&nbsp;<b className="fw-bold">Long:</b> ${lng
              .toString()
              .replace('.', ',')}`;
            setAddress(feature);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  useEffect(() => {
    if (lng != null && lat != null) {
      handleAddressByCoords(lng, lat);
    }
  }, [lat, lng]);

  return (
    <Row className="flex-column text-center">
      {id !== undefined ? (
        <Col className={'fw-bold blue-text-rak pb-1 text-uppercase'}>
          <b>
            {t([`quote.details.strategy_quote.title`])} <span>({id})</span>
          </b>
        </Col>
      ) : (
        <Col className={'fw-bold blue-text-rak pb-1 text-uppercase'}>
          <b>{t([`quote.details.strategy_quote.point`])}</b>
        </Col>
      )}
      {targetType !== undefined && (
        <Col className={'fw-bold'}>
          <b>
            {t([
              `quote.details.strategy_quote.strategy_type_labels.${targetType}`,
            ])}{' '}
            <span>{point?.code}</span>
          </b>
        </Col>
      )}
      <Col className="fw-bold">
        <span title={point?.name}>{point?.name}</span>
      </Col>
      {address !== '' && (
        <Col className="pt-2">
          <p
            className="p-0 m-0"
            dangerouslySetInnerHTML={{ __html: address }}
          ></p>
        </Col>
      )}
    </Row>
  );
};
