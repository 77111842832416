/* eslint-disable @typescript-eslint/prefer-optional-chain */
import L from 'leaflet';
import React from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { MAP_URL } from 'utils/constants';
import {
  type AlternativePaths,
  type IPointCoords,
  type IStrategyQuote,
} from 'models/Quote';
import { MapQuoteStrategyChild } from './MapQuoteStrategyChild';

interface QuoteStrategyProps {
  pointCoords: IPointCoords;
  strategy: IStrategyQuote;
  pathSelected?: AlternativePaths;
}

export const MapQuoteStrategy: React.FC<QuoteStrategyProps> = ({
  pointCoords,
  strategy,
  pathSelected,
}): React.ReactElement => {
  const center = L.latLng(strategy.pointCoords?.lat, strategy.pointCoords?.lng);
  const zoom = 16;

  return (
    <>
      {strategy !== undefined && (
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={true}
          minZoom={7}
          maxZoom={18}
          zoomControl={false}
          dragging={true}
          doubleClickZoom={true}
          id={`strategy-${strategy.id}`}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={MAP_URL}
          />
          <ZoomControl position="bottomleft" />
          <div id="collapse-carte">
            {pathSelected != null && (
              <MapQuoteStrategyChild
                strategy={strategy}
                pointCoords={pointCoords}
                pathSelected={pathSelected}
              />
            )}
          </div>
        </MapContainer>
      )}
    </>
  );
};

export default MapQuoteStrategy;
