import React, { useContext, useEffect } from 'react';
import { Col, Collapse, Container, Row } from 'react-bootstrap';
import Header from 'components/header';
import Search from 'components/strategy/search';
import { Outlet, useLocation } from 'react-router-dom';
import FiltersContext from 'context/FiltersContext';
import { type StrategyContextType, type FiltersContextType } from 'types';
import { useDeviceType } from 'shared/useDeviceType';
import StrategyContext from 'context/StrategyContext';
import { Loading } from 'shared/Loading';
import { ToastContainer } from 'react-toastify';

const AppLayout: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/');

  const { filtersContext } = useContext<FiltersContextType>(FiltersContext);

  const { strategyContext, setStrategyContext } =
    useContext<StrategyContextType>(StrategyContext);

  const { isMobile, isTablet } = useDeviceType();
  const isDesktop = !isMobile && !isTablet;

  useEffect(() => {
    // Check is Desktop: to display Carte & Strategy
    if (isDesktop) {
      setStrategyContext({
        ...strategyContext,
        openMap: true,
        openStrategy: true,
      });
    }
  }, [strategyContext.openMap, strategyContext.openStrategy]);

  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row className={`sticky-header header-${currentPath[1]}`}>
          <Col md="12">
            <Header />
          </Col>
          {currentPath[1] === '' && (
            <Col md="12">
              <Collapse in={filtersContext.openSearch}>
                <div id="collapse-search" className="collapse-search">
                  <Search />
                </div>
              </Collapse>
            </Col>
          )}
        </Row>
        <Row className={'sticky-map'}>
          <Outlet />
        </Row>
      </Container>

      {strategyContext.isLoading && <Loading message={`Loading ...`} />}
    </>
  );
};

export default AppLayout;
