/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import QuoteContext from 'context/QuoteContext';
import { type IQuotePost, type IStrategyQuote } from 'models/Quote';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { setQuoteStrategy } from 'services/quotes';
import { type QuoteContextType } from 'types';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'services/NotificationService';

interface StrategyProps {
  strategy?: IStrategyQuote;
  quoteUid: string;
  quoteId: string;
  idStrategy: string;
  isDesktop: boolean;
}

const DiscardedStrategy: React.FC<StrategyProps> = ({
  strategy,
  quoteUid,
  quoteId,
  idStrategy,
  isDesktop,
}): React.ReactElement => {
  const { t } = useTranslation();
  const { quoteContext, setQuoteContext } =
    useContext<QuoteContextType>(QuoteContext);

  const {
    quoteContext: { data },
  } = useContext<QuoteContextType>(QuoteContext);

  const [quotePost, setQuotePost] = useState<IQuotePost>({
    discarded: false,
  });

  useEffect(() => {
    setQuotePost({ ...quotePost, discarded: strategy?.discarded });
  }, [strategy?.discarded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const discarded = e.target.checked;
    setQuotePost({ ...quotePost, discarded });
    handleDiscarded(discarded);
  };

  // control form checkebox Discarded
  const handleDiscarded = (discarded: boolean): void => {
    if (strategy !== undefined) {
      void setQuoteStrategy(quoteUid, strategy.uid, { discarded }).then(
        (response: any) => {
          if (response.success) {
            showSuccessNotification(
              t('quote.details.strategy_quote.discarded_strategy.success'),
            );
            quoteId &&
              idStrategy &&
              updateQuoteByStrategy(quoteId, idStrategy, { discarded });
          } else {
            showErrorNotification(
              t(
                'quote.details.strategy_quote.discarded_strategy.errors.required',
              ),
            );
          }
        },
      );
    }
  };

  // update context after run API
  const updateQuoteByStrategy = (
    quoteId: string,
    idStrategy: string,
    form: IQuotePost,
  ) => {
    const newContext = {
      ...quoteContext,
      data,
    };

    const indexQuote = newContext.data.findIndex(
      (quote) => quote.id == quoteId,
    );
    const indexStrategy = newContext.data[indexQuote].strategies.findIndex(
      (strategy) => strategy.id == idStrategy,
    );

    newContext.data[indexQuote].strategies[indexStrategy] = {
      ...newContext.data[indexQuote].strategies[indexStrategy],
      discarded: form.discarded,
    };

    setQuoteContext(newContext);
  };

  return (
    <>
      {strategy && (
        <>
          {isDesktop && (
            <h4 className="mx-2">
              <strong
                className={quotePost.discarded === true ? 'discarded' : ''}
              >{`${t(
                'quote.details.strategy_quote.discarded_strategy.strategy',
              )} ${strategy?.id}`}</strong>{' '}
            </h4>
          )}

          <Form.Check
            type="switch"
            id={`discarded-${strategy?.id}`}
            name={`discarded-${strategy?.id}`}
            label={
              isDesktop
                ? t('quote.details.strategy_quote.discarded_strategy.title')
                : ''
            }
            checked={quotePost.discarded}
            onChange={handleChange}
          />
        </>
      )}
    </>
  );
};

export default DiscardedStrategy;
