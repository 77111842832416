/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import { type FiltersContextType, type StrategyContextType } from 'types';
import StrategyContext from 'context/StrategyContext';
import { type IStrategy } from 'models/Strategy';
import FiltersContext from 'context/FiltersContext';
import CustomMarker from 'components/strategy/map/CustomMarker';
import CustomPolyline from './CustomPolyline';

interface CustomPolygonProps {
  bounds: Array<[number, number]>;
}
export const CustomPolygon: React.FC<CustomPolygonProps> = ({ bounds }) => {
  const { strategyContext } = useContext<StrategyContextType>(StrategyContext);
  const { selectedStrategies, data, isLoading } = strategyContext;

  const { filtersContext } = useContext<FiltersContextType>(FiltersContext);
  const currentSelectAddressLat: number | any = filtersContext.coordinates?.lat;
  const currentSelectAddressLng: number | any = filtersContext.coordinates?.lng;

  const map = useMap();
  useEffect(() => {
    if (!isLoading) {
      map?.invalidateSize();

      // Disable dragging
      map.dragging.disable();

      // Move map to center
      map
        .setView([currentSelectAddressLat, currentSelectAddressLng], 10.2)
        .getCenter();

      // Re-enable dragging
      map.dragging.enable();
    }
  }, [currentSelectAddressLat, currentSelectAddressLng]);

  return (
    <>
      {data.map((strategy: IStrategy) => {
        const {
          id,
          uid,
          polylineCoords,
          pointCoords: { label, lat, lng },
          tension_nbr,
        } = strategy;

        return (
          <FeatureGroup key={uid}>
            <CustomPolyline
              positions={polylineCoords}
              isActive={selectedStrategies.includes(uid)}
              tension_nbr={tension_nbr}
              bounds={bounds}
              uid={uid}
              point={strategy.point}
              targetType={strategy.targetType}
            >
              <CustomMarker
                label={label}
                lng={lng}
                lat={lat}
                id={id}
                position={false}
                point={strategy.point}
                targetType={strategy.targetType}
              />
            </CustomPolyline>
          </FeatureGroup>
        );
      })}
    </>
  );
};

export default CustomPolygon;
