import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { API_ADDRESS_URL_REVERSE } from 'utils/constants';
interface PointPopupProps {
  label?: string;
  lat?: number;
  lng?: number;
  id?: number;
  idTrace?: string;
  power?: number;
  point?: any;
  targetType?: string;
}

export const PointPopupTrace: React.FC<PointPopupProps> = ({
  lat,
  lng,
  label,
  id,
  power,
  idTrace,
  point,
  targetType,
}: PointPopupProps): React.ReactElement => {
  const { t } = useTranslation();

  const [address, setAddress] = useState<string>('');

  const handleAddressByCoords = (lng: number, lat: number): void => {
    if (lat !== 0 && lng !== 0) {
      fetch(`${API_ADDRESS_URL_REVERSE}?lon=${lng}&lat=${lat}`)
        .then(async (response) => await response.json())
        .then((result) => {
          const feature = result.features[0];
          setAddress(feature.properties.label);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  useEffect(() => {
    if (lng !== undefined && lat !== undefined) {
      handleAddressByCoords(lng, lat);
    }
  }, [lat, lng]);

  return (
    <Row className="flex-column text-center">
      {id !== undefined && (
        <Col className={'fw-bold blue-text-rak pb-1 text-uppercase'}>
          <b>
            {t([`quote.details.strategy_quote.title`])} <span>({idTrace})</span>
          </b>
        </Col>
      )}
      {targetType !== undefined && (
        <Col className={'fw-bold'}>
          <b>
            {t([
              `quote.details.strategy_quote.strategy_type_labels.${targetType}`,
            ])}{' '}
            <span>{point?.code}</span>
          </b>
        </Col>
      )}
      <Col className="fw-bold">
        <span title={point?.name}>{point?.name}</span>
      </Col>
      {address !== '' && <Col className="fw-bold pt-2">{address}</Col>}
    </Row>
  );
};
