import type L from 'leaflet';
import { type LatLngExpression } from 'leaflet';
import React, { useState } from 'react';
import { Polyline } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
// import { useDeviceType } from 'shared/useDeviceType';
// import { PointPopup } from '../../../shared/PointPopup';
import { useNavigate, useParams } from 'react-router-dom';

interface CustomPolylineProps {
  positions: LatLngExpression[] | LatLngExpression[][];
  children?: React.ReactNode;
  isActive?: boolean;
  power?: number;
  label: string;
  id: string;
  lat: number;
  lng: number;
  point?: any;
  targetType?: string;
}

const QuotePolyline: React.FC<CustomPolylineProps> = ({
  isActive,
  positions,
  power,
  label,
  id,
  lat,
  lng,
  children,
  point,
  targetType,
}) => {
  const polylineRef = React.useRef<L.Polyline | null>(null);
  // const { isDesktop } = useDeviceType();
  const [polylineWeight, setPolylineWeight] = useState(12);
  const { quoteId } = useParams<{ quoteId: string }>();
  const navigate = useNavigate();

  return (
    <>
      <Polyline
        pathOptions={{
          opacity: 1,
          weight: polylineWeight,
          stroke: true,
          color: '#00B0FF',
          className: 'shadow-box',
        }}
        positions={positions}
        weight={polylineWeight}
        eventHandlers={{
          mouseover: (e: L.LeafletMouseEvent) => {
            setPolylineWeight(20);
            e.target.openPopup();
          },
          mouseout: (e: L.LeafletMouseEvent) => {
            setPolylineWeight(12);
            e.target.closePopup();
          },
          click: () => {
            if (quoteId !== undefined) {
              navigate(`/quote/${quoteId}/strategy/${id}`);
            }
          },
        }}
        ref={polylineRef}
      >
        {children}
        {/* {isDesktop && (
          <Popup closeButton={false}>
            <PointPopup
              lat={lat}
              lng={lng}
              point={point}
              targetType={targetType}
            />
          </Popup>
        )} */}
      </Polyline>
    </>
  );
};

export default QuotePolyline;
