/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import L from 'leaflet';
import React, { useRef } from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';

import { MAP_URL } from 'utils/constants';
import { type IStrategyQuote, type IPointCoords } from 'models/Quote';
import MapQuoteChild from './MapQuoteChild';

interface QuoteStrategyProps {
  pointCoords: IPointCoords;
  strategies?: IStrategyQuote[];
  isLoading?: boolean;
}

export const MapQuote: React.FC<QuoteStrategyProps> = ({
  strategies,
  pointCoords,
  isLoading,
}): React.ReactElement => {
  const center = L.latLng(pointCoords?.lat, pointCoords?.lng);
  const zoom = 16;

  const mapRef = useRef<any>(null); // Use a ref to access the map instance

  // useEffect(() => {
  //   if (mapRef.current) {
  //     const map = mapRef.current.leafletElement;
  //     map?.invalidateSize();

  //     // Disable dragging
  //     map.dragging.disable();
  //     map && map.setView(center, zoom).getCenter();
  //     // Re-enable dragging
  //     map.dragging.enable();
  //   }
  // }, [mapRef]);

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={true}
      minZoom={7}
      maxZoom={18}
      zoomControl={false}
      dragging={true}
      doubleClickZoom={true}
      ref={mapRef}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={MAP_URL}
      />
      <ZoomControl position="bottomleft" />
      {pointCoords?.lng !== 0 && pointCoords?.lat !== 0 && (
        <div id="collapse-carte">
          <MapQuoteChild pointCoords={pointCoords} strategies={strategies} />
        </div>
      )}
    </MapContainer>
  );
};

export default MapQuote;
