/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, { type AxiosError } from 'axios';
import {
  type ICostPost,
  type IQuote,
  type IQuotePost,
  type QuoteForm,
} from 'models/Quote';

import { api } from './api';

// CONNEXION WITH BACKEND
interface ServerError {
  errorMessage: string;
}

export const getQuoteList = async (): Promise<any> => {
  try {
    const response = await api.get<IQuote[]>(
      `/devis?timestamp=${new Date().getTime()}`,
    );

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        const response = serverError.response;
        return {
          data: response.data,
          status: response.status,
        };
      }
    }
    return { errorMessage: 'error !' };
  }
};

export const getDetailsQuote = async (quoteUid: string): Promise<any> => {
  try {
    const response = await api.get<IQuote>(
      `/devis/${quoteUid}?timestamp=${new Date().getTime()}`,
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        return serverError.response.data;
      }
    }
    return { errorMessage: 'error !' };
  }
};

export const setQuoteStrategy = async (
  quoteUid: string,
  stratUid: string,
  quotePost: IQuotePost,
): Promise<any> => {
  try {
    const response = await api.post(
      `/devis/${quoteUid}/strat/${stratUid}?timestamp=${new Date().getTime()}`,
      quotePost,
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        return serverError.response.data;
      }
    }
    return { errorMessage: 'error !' };
  }
};

export const deleteQuote = async (quoteUid: string): Promise<any> => {
  try {
    return await api.delete(
      `/devis/${quoteUid}?timestamp=${new Date().getTime()}`,
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        return serverError.response.data;
      }
    }
    return { errorMessage: 'error !' };
  }
};

export const deleteQuoteStrategy = async (
  quoteUid: string,
  stratUid: string,
): Promise<any> => {
  try {
    return await api.delete(
      `/devis/${quoteUid}/strat/${stratUid}?timestamp=${new Date().getTime()}`,
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        return serverError.response.data;
      }
    }
    return { errorMessage: 'error !' };
  }
};

// const headers = {
//   // 'Access-Control-Allow-Origin': '*',
//   // 'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
//   // 'Content-Type': 'application/json',
// };

// Save a new Quote
export const postQuote = async (quote: QuoteForm): Promise<any> => {
  try {
    return await api.post(`/devis?timestamp=${new Date().getTime()}`, quote);
  } catch (error: any) {
    return {
      success: false,
      status: error?.response?.status,
      data: error?.response?.data,
    };
  }
};

export const getPostConfig = async (tension: number): Promise<any> => {
  try {
    const response = await api.get(
      `/poste/config/${tension}?timestamp=${new Date().getTime()}`,
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        return serverError.response.data;
      }
    }
    return { errorMessage: 'error !' };
  }
};

export const setCostPoste = async (
  quoteUid: string,
  stratUid: string,
  cost: ICostPost,
): Promise<any> => {
  try {
    return await api.post(`/devis/${quoteUid}/strat/${stratUid}`, cost);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        return serverError.response.data;
      }
    }
    return { errorMessage: 'error !' };
  }
};

export const getQuantitiesQuote = async (uid: string): Promise<any> => {
  try {
    const response = await api.get(`/devis/${uid}`);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ServerError>;
      if (serverError?.response != null) {
        return serverError.response.data;
      }
    }
    return { errorMessage: 'error !' };
  }
};
