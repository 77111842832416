/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { type IStrategyQuote } from 'models/Quote';
import { deleteQuoteStrategy } from 'services/quotes';
import { showSuccessNotification } from 'services/NotificationService';

interface ModalProps {
  detailsStrategy: IStrategyQuote;
  quoteUid: string;
  quoteId: string;
  isOpen: boolean;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  font-family: Open Sans;
  font-style: normal;
  color: #212121;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
        color: #6c6c6c;
        text-decoration: none;
      }

      &.btn-primary {
        border-radius: 4px;
        border: 1px solid #009cdf;
        background: #009cdf;
        color: #fff;
        text-align: center;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }

      &.btn-outline-light {
        border-radius: 4px;
        border: 1px solid #009cdf;
        background: #fff;
        color: #009cdf;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }
    }
  }

  h3 {
    color: #000;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 140% */
    text-transform: uppercase;
  }

  .modal-title {
    color: #000;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 140% */
    text-transform: uppercase;
  }

  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;

    .list-group {
      --bs-list-group-border-color: transparent;
      --bs-list-group-border-width: 0;
      .list-group-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .w-10 {
          width: 10%;
        }

        .badge.bg-primary {
          font-size: 16px;
        }
      }
    }
  }
`;

const DeleteStrategyModal: React.FC<ModalProps> = ({
  detailsStrategy,
  quoteUid,
  quoteId,
  isOpen,
  onClose,
}): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleDelete = (): void => {
    setLoading(true);

    void deleteQuoteStrategy(quoteUid, detailsStrategy?.uid).then((result) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      // if (result.success) {
      showSuccessNotification(`${t('quote.details.delete_strategy.success')}`);
      onClose();
      setLoading(false);
      navigate(`/quote/${quoteId}`, { state: { quoteUid } });
      // }
    });
  };

  return (
    <StyledModal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${t('quote.details.delete_strategy.title')} ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          detailsStrategy?.id != null && detailsStrategy?.id
        }`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('quote.details.delete_strategy.text')}</Modal.Body>
      <Modal.Footer className="mt-2">
        <Button variant="link" onClick={onClose}>
          {t('quote.details.delete_strategy.button.close')}
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          className="d-flex align-items-center"
        >
          <i className="icon icone-delete me-1"></i>
          {t('quote.details.delete_strategy.button.delete')}
          {isLoading && (
            <Spinner className="spinner" animation="border" size="sm" />
          )}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default DeleteStrategyModal;
