import React, { useContext, useEffect } from 'react';
import L, { type PointExpression } from 'leaflet';
import { Marker, Popup, useMap } from 'react-leaflet';
import { useDeviceType } from '../../../shared/useDeviceType';
import { type StrategyContextType } from 'types';
import StrategyContext from 'context/StrategyContext';
import { PointPopup } from 'shared/PointPopup';
import ImgMarker from 'images/marker.svg';

interface CustomMarkerProps {
  children?: React.ReactNode;
  lat: number;
  lng: number;
  id?: string;
  label?: string;
  position?: boolean;
  point?: any;
  targetType?: string;
}

const CustomMarker: React.FC<CustomMarkerProps> = ({
  lng,
  lat,
  id,
  label,
  position,
  point,
  targetType,
}: CustomMarkerProps): React.ReactElement => {
  const { strategyContext, setStrategyContext } =
    useContext<StrategyContextType>(StrategyContext);

  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);

  const { isMobile, isTablet, isDesktop } = useDeviceType();

  const IconMarker = new L.Icon({
    iconUrl: ImgMarker,
    iconAnchor: [13, 32],
    popupAnchor: [10, -20],
    iconSize: [30, 35],
  });

  const IconMarkerHtml = (id: string): any => {
    return `<div class="svg-icon-marker">${id}</div>`;
  };

  // Create a custom DivIcon with event listeners
  const iconOptions: L.DivIconOptions = {
    className: 'custom-div-icon-marker',
    html: id !== undefined && IconMarkerHtml(id),
    iconAnchor: [16, 25],
    iconSize: [34, 34],
  };

  const customDivIcon = position === true ? IconMarker : L.divIcon(iconOptions);
  const popupOptions: PointExpression = position === true ? [-6, 10] : [-1, 10];

  return (
    <Marker
      position={[lat, lng]}
      icon={customDivIcon}
      draggable={false}
      eventHandlers={{
        mouseover: (e: L.LeafletMouseEvent) => e.target.openPopup(),
        mouseout: (e: L.LeafletMouseEvent) => e.target.closePopup(),
        click: (e) => {
          if (isMobile || isTablet) {
            setStrategyContext({
              ...strategyContext,
              selectedStrategy: id,
              openStrategyDetails: true,
            });
          }
        },
      }}
    >
      {isDesktop && (
        <Popup closeButton={false} offset={popupOptions}>
          <PointPopup
            lat={lat}
            lng={lng}
            point={point}
            targetType={targetType}
            id={id}
          />
        </Popup>
      )}
    </Marker>
  );
};

export default CustomMarker;
