import React from 'react';
import { Modal } from 'react-bootstrap';
// import { PDFViewer } from '@react-pdf/renderer';
import styled from 'styled-components';
import CreateForm from './CreateForm';
// import StrategyContext from 'context/StrategyContext';
// import { type StrategyContextType } from 'types';
// import { StrategyPdf } from './StrategyPdf';
// import { type IStrategy } from 'models/Strategy';

interface StrategiesGenaratorProps {
  show: boolean;
  handleClose: () => void;
}

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 1rem;
  }
  .modal-body {
    padding: 0;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
  .modal-title {
    font-family: $font-family-open;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 11px;
    display: flex;
    align-items: center;
    color: var(--bs-modal-color);
  }
  .form-control-rak {
    width: 100%;
  }
`;

const CreateQuote: React.FC<StrategiesGenaratorProps> = ({
  show,
  handleClose,
}) => {
  const handleCloseModal = (): void => {
    handleClose();
  };

  return (
    <>
      <StyledModal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size={'md'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Information à renseigner pour envoi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateForm handleCloseModal={handleCloseModal} />
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default CreateQuote;
