/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deleteQuote } from 'services/quotes';

import { showSuccessNotification } from 'services/NotificationService';

interface ModalProps {
  quoteId: string;
  quoteUid: string;
  isOpen: boolean;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  font-family: Open Sans;
  font-style: normal;
  color: #212121;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
        color: #6c6c6c;
        text-decoration: none;
      }
    }
  }

  .modal-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 10.879px;
  }
  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }
`;

const DeleteQuoteModal: React.FC<ModalProps> = ({
  quoteId,
  quoteUid,
  isOpen,
  onClose,
}): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleDeleteQuote = (): void => {
    setLoading(true);

    void deleteQuote(quoteUid).then((result) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (result.data.success) {
        showSuccessNotification(`${t('quote.details.delete_quote.success')}`);
        setLoading(false);
        navigate(`/quote`);
      }
    });
  };

  return (
    <StyledModal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${t(
          'quote.details.delete_quote.title',
        )} ${quoteId}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{`${t('quote.details.delete_quote.text')}`} </Modal.Body>
      <Modal.Footer className="mt-2">
        <Button variant="link" onClick={onClose}>
          {t('quote.details.delete_quote.button.close')}
        </Button>
        <Button
          variant="danger"
          onClick={handleDeleteQuote}
          className="d-flex align-items-center"
        >
          <i className="icon icone-delete me-1"></i>
          {t('quote.details.delete_quote.button.delete')}
          {isLoading && (
            <Spinner className="spinner" animation="border" size="sm" />
          )}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default DeleteQuoteModal;
