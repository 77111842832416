import React, { useContext, useState } from 'react';
import Logo from 'images/logo.svg';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import FiltersContext from 'context/FiltersContext';
import StrategyContext from 'context/StrategyContext';
import { type FiltersContextType, type StrategyContextType } from 'types';
import { useTranslation } from 'react-i18next';
import LoginModal from 'components/login';
import InitLoginModal from 'components/login/Modal';
import { userIsLogged } from 'services/user';

const Header: React.FC = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const { filtersContext, setFiltersContext } =
    useContext<FiltersContextType>(FiltersContext);

  const { strategyContext, setStrategyContext } =
    useContext<StrategyContextType>(StrategyContext);

  const resetContext = (): void => {
    setExpanded(false);

    setFiltersContext({
      ...filtersContext,
      isSent: false,
      openSearch: false,
      clientType: '',
      power: 0,
      coordinates: {
        label: '',
        lng: 0,
        lat: 0,
      },
    });
    setStrategyContext({
      ...strategyContext,
      data: [],
      selectedStrategies: [],
      openStrategy: false,
    });
  };

  return (
    <>
      {!userIsLogged() && <InitLoginModal />}
      <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand href="/">
            <div className="logo">
              <img src={Logo} alt="logo" />
              <div className="separator"></div>
              <h1>{t('header.title')}</h1>
            </div>
          </Navbar.Brand>
          <Nav className="me-auto">
            <NavLink
              to="/"
              onClick={resetContext}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <i className="icon icon-map"></i>{' '}
              <span>{t('header.menu.new_connection')}</span>
            </NavLink>
            {userIsLogged() && (
              <NavLink
                onClick={resetContext}
                to="/quote"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <i className="icon icon-description"></i>{' '}
                <span>{t('header.menu.connection_quote')}</span>
              </NavLink>
            )}
          </Nav>
          <LoginModal />
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
