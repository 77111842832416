/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useState } from 'react';
import { Col, Form, Row, Modal, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { type StrategyContextType, type FiltersContextType } from 'types';
import { useTranslation } from 'react-i18next';

import FiltersContext from 'context/FiltersContext';
import { ClientTypeLabels, ClientTypes, EneryTypeLabels } from 'enums';
import { type QuoteForm } from 'models/Quote';
import StrategyContext from 'context/StrategyContext';
import { formattedDateEn } from 'utils';
import { postQuote } from 'services/quotes';
import { useNavigate } from 'react-router-dom';
import { showErrorNotification } from 'services/NotificationService';
import CustomDatePicker from './CustomDatePicker';

const StyledForm = styled(Form)`
  .form-label {
    font-weight: 700;
  }
  .modal-body {
    padding: 0;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
    .spinner {
      margin-left: 10px;
    }
  }

  .contact-form {
    border: 0.0625rem solid #cccccc;
    border-radius: 0.1875rem;
    position: relative;

    .row {
      padding: 1rem;
    }

    .form-control-rak {
      width: 95%;
    }

    h4 {
      font-family: $font-family-open;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 0.625rem;
      display: flex;
      align-items: center;
      color: var(--bs-modal-color);
      position: absolute;
      z-index: 999;
      top: -0.625rem;
      left: 0.625rem;
      padding: 0.3125rem 0.625rem;
      z-index: 1;

      &:after {
        content: ' ';
        background-color: #fff;
        width: 100%;
        height: 13px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
      }
    }
  }
`;
interface ClientFormProps {
  handleCloseModal: () => void;
}
const ClientForm: React.FC<ClientFormProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation();

  const {
    filtersContext: { coordinates, clientType, power },
  } = useContext<FiltersContextType>(FiltersContext);
  const {
    strategyContext: { selectedStrategies },
  } = useContext<StrategyContextType>(StrategyContext);

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<QuoteForm>({
    address: coordinates?.label ?? '',
    lat: coordinates?.lat ?? 0,
    long: coordinates?.lng ?? 0,
    contactName: '',
    companyName: '',
    siret: '',
    email: '',
    phone: '',
    clientType,
    dateDevis: formattedDateEn(new Date()),
    dateMiseEnService: '',
    energyType: '',
    strategyIds: selectedStrategies,
    power,
  });

  const [errors, setErrors] = useState({
    companyName: '',
    energyType: '',
  });

  const required = (value: string): boolean => {
    return value !== '';
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (selectedDate: Date | null) => {
    if (selectedDate !== null) {
      setFormData({
        ...formData,
        dateMiseEnService: formattedDateEn(selectedDate),
      });
    }
  };

  const handleCreateQuote = (e: React.FormEvent): void => {
    e.preventDefault();

    // Validation
    const newErrors = {
      companyName: '',
      energyType: '',
    };

    if (formData.companyName !== undefined && !required(formData.companyName)) {
      newErrors.companyName = t('strategy.form.errors.required');
    }

    if (
      clientType === ClientTypes.PRODUCER &&
      formData.energyType !== undefined &&
      !required(formData.energyType)
    ) {
      newErrors.energyType = t('strategy.form.errors.required');
    }

    setErrors(newErrors);

    // If there are no errors, you can submit the form
    if (newErrors.companyName === '' && newErrors.energyType === '') {
      // Handle form submission here
      setLoading(true);
      void postQuote(formData)
        .then((response: any) => {
          if (response.status === 200) {
            setLoading(false);
            handleCloseModal();
            navigate(`/quote`);
          } else if (response.status === 401) {
            setLoading(false);
            showErrorNotification(t('strategy.form.errors.401'));
          } else if (response.status === 403) {
            setLoading(false);
            showErrorNotification(t('strategy.form.errors.403'));
          } else {
            setLoading(false);
            showErrorNotification(t('strategy.form.errors.500'));
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log('error', error);
          showErrorNotification(t('strategy.form.errors.500'));
        });
    }
  };

  return (
    <StyledForm noValidate>
      <Modal.Body>
        <Row className="flex-column">
          <Col sm={12}>
            <Row className="contact-form mt-2 mb-3">
              <Col sm={12}>
                <h4>{t('strategy.form.company')}</h4>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-2" controlId="companyName">
                      <Form.Label>
                        {t('strategy.form.company_name')}
                        <span className="required">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="companyName"
                        bsPrefix="form-control-rak"
                        value={formData.companyName}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          handleInputChange(e);
                        }}
                        isInvalid={!(errors.companyName === '')}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.companyName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="siret">
                      <Form.Label>{t('strategy.form.siret')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="siret"
                        bsPrefix="form-control-rak"
                        value={formData.siret}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          handleInputChange(e);
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-2">
                      <Form.Label>{t('strategy.form.type_company')}</Form.Label>
                      <Form.Control
                        type="text"
                        bsPrefix="form-control-rak"
                        placeholder={
                          ClientTypeLabels[
                            formData.clientType as keyof typeof ClientTypeLabels
                          ]
                        }
                        autoComplete="off"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="contact-form mb-3">
              <Col sm={12}>
                <h4>{t('strategy.form.contact')}</h4>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-2" controlId="contactName">
                      <Form.Label>{t('strategy.form.name')}</Form.Label>
                      <Form.Control
                        type="text"
                        bsPrefix="form-control-rak"
                        name="contactName"
                        value={formData.contactName}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          handleInputChange(e);
                        }}
                        autoComplete="none"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-2" controlId="email">
                      <Form.Label>{t('strategy.form.email')}</Form.Label>
                      <Form.Control
                        type="text"
                        bsPrefix="form-control-rak"
                        name="email"
                        value={formData.email}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          handleInputChange(e);
                        }}
                        autoComplete="none"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-2" controlId="phone">
                      <Form.Label>{t('strategy.form.phone')}</Form.Label>
                      <Form.Control
                        type="text"
                        bsPrefix="form-control-rak"
                        name="phone"
                        value={formData.phone}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          handleInputChange(e);
                        }}
                        autoComplete="none"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="contact-form">
              <Col sm={12}>
                <h4>{t('strategy.form.request')}</h4>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-2">
                      <Form.Label>{t('strategy.form.power')}</Form.Label>
                      <Form.Control
                        type="number"
                        bsPrefix="form-control-rak"
                        name="power"
                        value={formData.power}
                        autoComplete="off"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mb-2">
                    <CustomDatePicker onChange={handleDateChange} />
                  </Col>
                  {clientType === ClientTypes.PRODUCER && (
                    <Col sm={6}>
                      <Form.Group className="mb-2" controlId="energyType">
                        <Form.Label>
                          {t('strategy.form.energy_type')}{' '}
                          <span className="required">*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={handleInputChange}
                          name="energyType"
                          bsPrefix="form-control-rak"
                          isInvalid={
                            clientType === ClientTypes.PRODUCER &&
                            !(errors.energyType === '')
                          }
                          autoComplete="off"
                        >
                          <option value={''}>...</option>
                          {Object.keys(EneryTypeLabels).map((elt) => {
                            return (
                              <option key={elt} value={elt}>
                                {
                                  EneryTypeLabels[
                                    elt as keyof typeof EneryTypeLabels
                                  ]
                                }
                              </option>
                            );
                          })}
                        </Form.Select>
                        {clientType === ClientTypes.PRODUCER && (
                          <Form.Control.Feedback type="invalid">
                            {errors.energyType}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-rak-primary" onClick={handleCreateQuote}>
          {t('strategy.form.button')}
          {loading && (
            <Spinner className="spinner" animation="border" size="sm" />
          )}
        </Button>
      </Modal.Footer>
    </StyledForm>
  );
};

export default ClientForm;
