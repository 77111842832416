/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react';
import { type IStrategyQuote, type IPointCoords } from 'models/Quote';
import QuotePolygon from './QuotePolygon';
import QuoteMarker from './QuoteMarker';
import { useMap } from 'react-leaflet';

interface MapQuoteChildProps {
  pointCoords: IPointCoords;
  strategies?: IStrategyQuote[];
}

export const MapQuoteChild: React.FC<MapQuoteChildProps> = ({
  strategies,
  pointCoords,
}): React.ReactElement => {
  const map = useMap();
  useEffect(() => {
    map.invalidateSize();
    if (strategies !== undefined && strategies.length > 0) {
      const bounds: Array<[number, number]> = strategies.map(
        ({ pointCoords: { lat, lng } }) => {
          return [lat, lng];
        },
      );

      map.fitBounds([...bounds, [pointCoords.lat, pointCoords.lng]]);
    }
  }, []);

  return (
    <>
      <QuoteMarker
        lng={Number(pointCoords?.lng)}
        lat={Number(pointCoords?.lat)}
        label={pointCoords?.label}
        position={true}
      />
      <QuotePolygon pointCoords={pointCoords} strategies={strategies} />
    </>
  );
};

export default MapQuoteChild;
