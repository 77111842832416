/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { type IPointQuote } from 'models/Quote';

import { Alert, Badge, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import StrategyTitle from '../../strategy/strategy-item/StrategyTitle';
import { formattedPower, getCostValue, getdistance } from 'utils';
import { useDeviceType } from 'shared/useDeviceType';
import Thermometer from 'images/thermometer.svg';

interface QuoteDescriptionProps {
  point?: IPointQuote;
  connectionMessage?: string;
  distance?: number;
  cost?: number;
  id: string;
  targetType: string;
  isReady?: boolean;
  displayTitle?: boolean;
  tension?: number;
  discarded?: boolean;
  costsDetails?: any;
  lineType: string;
  temperatureAlert?: boolean;
}

export const StyledRow = styled(Row)`
  .flex-item {
    height: 1.5rem;
  }
  .alert-warning {
    --bs-alert-color: rgba(255, 153, 0, 1);
    --bs-alert-bg: transparent;
    --bs-alert-border-color: rgba(255, 153, 0, 1);
    width: 105px;
    padding: 1px 5px;
    border-radius: 2px;
    color: #f90;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .tension {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0px;
    text-align: center;
    color: rgb(9, 160, 225);
    border: 2px solid rgb(9, 160, 225);
    padding: 0.3rem;
    display: block;
    height: 1.6rem;
    margin-right: 0.4rem;
    margin-top: 0.3rem;
    border-radius: 3px;
  }

  .thermometer {
    text-align: center;
    padding: 0 0.1rem;
    display: block;
    height: 1.6rem;
    margin-right: 0.4rem;
    margin-top: 0.3rem;
  }
`;

export const DivCol = styled(Col)`
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-radius: 5px;
  background: #ddedf3;
  padding: 10px;
  width: 100%;

  &.discarded {
    background: rgb(255, 153, 0, 0.2);
  }

  @media (max-width: 575.98px) {
    font-size: 11px;
  }

  .border-center {
    background-color: #007fb6;
    height: 2rem;
    width: 1.68px;
    position: relative;
    top: 2px;
  }

  .custom .border-center {
    background-color: rgb(148, 148, 148);
    position: relative;
    width: 1.5px;
    top: -8px;
  }

  .price {
    strong {
      position: relative;
      left: 0;
    }
  }
`;

const StrategyQuote: React.FC<QuoteDescriptionProps> = ({
  point,
  connectionMessage,
  targetType,
  temperatureAlert,
  lineType,
  distance,
  cost,
  id,
  isReady,
  displayTitle = true,
  tension,
  discarded,
  costsDetails,
}): React.ReactElement => {
  const { isDesktop } = useDeviceType();
  const { t } = useTranslation();

  const [estimadedCost, setEstimadedCost] = useState({
    min: 0,
    max: 0,
  });

  const [estimadedDisplay, setEstimadedDisplay] = useState(false);

  const getEstimadedCost = (typeStrategy: string, costsDetails: any): void => {
    if (typeStrategy === 'SUBSTATION' && costsDetails.isCostInitialized) {
      setEstimadedCost({
        ...estimadedCost,
        min: costsDetails.total.p50,
        max: costsDetails.total.p70,
      });
      setEstimadedDisplay(false);
    }
    if (typeStrategy === 'SUBSTATION' && !costsDetails.isCostInitialized) {
      setEstimadedCost({
        ...estimadedCost,
        min: costsDetails.total.min,
        max: costsDetails.total.max,
      });
      setEstimadedDisplay(true);
    }
    if (typeStrategy === 'PYLON') {
      setEstimadedCost({
        ...estimadedCost,
        min: costsDetails.total.p50,
        max: costsDetails.total.p70,
      });
      setEstimadedDisplay(false);
    }
  };

  useEffect(() => {
    if (targetType && costsDetails !== undefined) {
      getEstimadedCost(targetType, costsDetails);
    }
  }, [targetType, costsDetails]);

  return (
    <StyledRow>
      <Col lg="6" md="6" xs="12" style={{ paddingRight: '5px' }}>
        <Row>
          {displayTitle && (
            <Col md="12" className="flex-item mb-3">
              {id !== '' && <StrategyTitle id={id} discarded={discarded} />}
            </Col>
          )}
          {!isDesktop && (
            <Col md="12" xs="12" className="d-flex justify-content-start">
              {tension !== undefined && (
                <span className="tension">{formattedPower(tension)}</span>
              )}
              <h6 className="mt-1">
                <Badge bg="primary">
                  {t([
                    `quote.details.strategy_quote.connector_type_labels.${targetType}`,
                  ])}{' '}
                </Badge>
              </h6>
            </Col>
          )}

          <Col md="12" xs="6" className="flex-item text-truncate">
            <b className="text-truncate">
              {t([
                `quote.details.strategy_quote.strategy_type_labels.${targetType}`,
              ])}{' '}
              <span title={point?.code} className="text-truncate">
                {point?.code}
              </span>
            </b>
          </Col>
          <Col md="12" xs="6" className="flex-item text-truncate">
            <span title={point?.name} className="text-truncate">
              {point?.name}
            </span>
          </Col>
          <Col md="12" className="flex-item text-truncate">
            <b title={point?.details} className="text-truncate">
              {point?.details}
            </b>
          </Col>
          {!(isReady ?? false) && (
            <Col md="12" className="flex-item text-truncate mt-2">
              <Alert variant={'warning'}>
                {t([`quote.details.strategy_quote.is_ready.${targetType}`])}{' '}
              </Alert>
            </Col>
          )}
        </Row>
      </Col>
      <Col lg="6" md="6" xs="11">
        <Row style={{ paddingRight: '0.3rem' }}>
          {isDesktop && (
            <Col md="12" xs="12" className="d-flex justify-content-end">
              {temperatureAlert && (
                <img src={Thermometer} className="thermometer" />
              )}
              {lineType !== undefined && (
                <span className="tension">
                  {lineType === 'GROUNDLINE' ? 'LS' : 'LA'}
                </span>
              )}
              {tension !== undefined && (
                <span className="tension">{formattedPower(tension)}</span>
              )}
              <h6 className="mt-1">
                <Badge bg="primary">
                  {t([
                    `quote.details.strategy_quote.connector_type_labels.${targetType}`,
                  ])}{' '}
                </Badge>
              </h6>
            </Col>
          )}

          {(distance !== undefined || cost !== undefined) && (
            <DivCol
              md="12"
              xs="12"
              className={`${discarded ?? false ? 'discarded' : ''}`}
            >
              <Row>
                <Col lg="4" md="4" xs="4">
                  <Row className="flex-column">
                    <Col>{t('quote.details.strategy_quote.distance')}</Col>
                    <Col
                      style={{
                        marginTop: '3px',
                      }}
                    >
                      <b>{distance != null && getdistance(distance)}</b>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg="1"
                  md="1"
                  xs="1"
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="border-center"></div>
                </Col>
                <Col lg="7" md="6" xs="7">
                  <Row className="flex-column">
                    <Col>
                      {costsDetails.isCostInitialized !== undefined &&
                      estimadedDisplay ? (
                        <Col>
                          <Row className="unit">
                            <Col style={{ width: '60%' }}>
                              {t(
                                'quote.details.strategy_quote.details_costs.estimated_costs.min',
                              )}
                            </Col>
                            <Col
                              style={{
                                width: '10%',
                                maxWidth: '10%',
                              }}
                            ></Col>
                            <Col style={{ width: '30%' }}>
                              {t(
                                'quote.details.strategy_quote.details_costs.estimated_costs.max',
                              )}
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col>
                          <Row className="unit">
                            <Col style={{ width: '60%' }}>
                              {t(
                                'quote.details.strategy_quote.details_costs.estimated_costs.p50',
                              )}
                            </Col>
                            <Col
                              style={{
                                width: '10%',
                                maxWidth: '10%',
                              }}
                            ></Col>
                            <Col style={{ width: '30%' }}>
                              {t(
                                'quote.details.strategy_quote.details_costs.estimated_costs.p70',
                              )}
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Col>
                    <Col
                      style={{
                        marginTop: '3px',
                      }}
                    >
                      <Row className={`m-0`}>
                        <Col>
                          <Row className={`flex-column cost m-0`}>
                            <Col style={{ marginTop: '0px' }}>
                              <Row>
                                <Col className={`fw-bolder`}>
                                  <strong>
                                    {getCostValue(estimadedCost.min)}
                                  </strong>
                                </Col>

                                <Col
                                  lg="1"
                                  md="1"
                                  xs="2"
                                  className="d-flex justify-content-center align-items-center custom"
                                >
                                  <div className="border-center"></div>
                                </Col>

                                <Col
                                  className={`fw-bolder ${
                                    isDesktop ? ' price' : ''
                                  }`}
                                  style={{
                                    marginLeft: isDesktop ? '3px' : '0px',
                                  }}
                                >
                                  <strong>
                                    {getCostValue(estimadedCost.max)}
                                  </strong>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </DivCol>
          )}
        </Row>
      </Col>
    </StyledRow>
  );
};

export default StrategyQuote;
