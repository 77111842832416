import { api } from './api';
import { type UserForm } from 'models/User';

interface TokenData {
  value: string;
  expires: Date;
}

interface TokenStoreData {
  access: TokenData;
  refresh: TokenData;
}

const defaultData = (): TokenStoreData => ({
  access: { value: '', expires: new Date(0) },
  refresh: { value: '', expires: new Date(0) },
});

const initTokenStore = (): void => {
  const strAccess = localStorage.getItem('accessToken');
  if (!(strAccess === null || strAccess === '')) {
    try {
      const data = JSON.parse(strAccess);
      TokensStore.data.refresh = {
        value: data.value,
        expires: new Date(data.expires),
      };
    } catch (error) {
      console.error('error parsing accessToken from LocalStorage');
    }
  }

  const strRefresh = localStorage.getItem('refreshToken');
  if (!(strRefresh === null || strRefresh === '')) {
    try {
      const data = JSON.parse(strRefresh);
      TokensStore.data.refresh = {
        value: data.value,
        expires: new Date(data.expires),
      };
    } catch (error) {
      console.error('error parsing refreshToken from LocalStorage');
    }
  }
};

const TokensStore = {
  data: defaultData(),
};

initTokenStore();

export const loginUser = async (user: UserForm): Promise<any> => {
  try {
    const response = await api.post(`/user/login`, user, {
      skipAuthRefresh: true,
    } as any);

    const { accessToken, accessTokenExpiry, refreshToken, refreshTokenExpiry } =
      response.data;

    setTokens({
      accessToken,
      accessTokenExpiry: new Date(accessTokenExpiry),
      refreshToken,
      refreshTokenExpiry: new Date(refreshTokenExpiry),
    });

    return {
      success: true,
      status: response.status,
      data: response.status,
    };
  } catch (error: any) {
    return {
      success: false,
      status: error?.response?.status,
      data: error?.response?.data,
    };
  }
};

export const logoutUser = async (): Promise<any> => {
  try {
    const refreshToken = getRefreshToken() ?? '';
    const response = await api.post(`/user/logout`, { refreshToken }, {
      skipAuthRefresh: true,
    } as any);
    clearTokens();

    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error: any) {
    return {
      success: false,
      status: error?.reponse?.status,
      data: error?.reponse?.data,
    };
  }
};

export function setAccessToken(data: TokenData): void {
  TokensStore.data.access = data;
  localStorage.setItem('accessToken', JSON.stringify(data));
}

export function setRefreshToken(data: TokenData): void {
  TokensStore.data.refresh = data;
  localStorage.setItem('refreshToken', JSON.stringify(data));
}

export function getAccessToken(): string | undefined {
  if (new Date() > TokensStore.data.access.expires) return;
  return TokensStore.data.access?.value;
}

export function getRefreshToken(): string | undefined {
  if (new Date() > TokensStore.data.refresh.expires) {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    return;
  }
  return TokensStore.data.refresh?.value;
}

// Set/Update access and refresh tokens after login
function setTokens(obj: {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiry: Date;
  refreshTokenExpiry: Date;
}): void {
  const { accessToken, accessTokenExpiry, refreshToken, refreshTokenExpiry } =
    obj;

  setAccessToken({
    value: accessToken,
    expires: accessTokenExpiry,
  });
  setRefreshToken({
    value: refreshToken,
    expires: refreshTokenExpiry,
  });
}

// (Optional) Clear tokens on logout
function clearTokens(): void {
  TokensStore.data = defaultData();
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

export function userIsLogged(): boolean {
  const isLoggedIn = (getRefreshToken() ?? '').length > 0;
  // console.log(
  //   `userIsLogged: ${isLoggedIn ? 'yes' : 'no'}. TokenStore2:`,
  //   TokensStore.data,
  // );
  return isLoggedIn;
}
