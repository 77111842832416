import React, { useEffect, useState } from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import { type IStrategyQuote, type IPointCoords } from 'models/Quote';
import QuoteMarker from './QuoteMarker';
import QuotePolyline from './QuotePolyline';

interface QuoteStrategyProps {
  pointCoords: IPointCoords;
  strategies?: IStrategyQuote[];
  isActive?: boolean;
}

export const QuotePolygon: React.FC<QuoteStrategyProps> = ({
  pointCoords,
  strategies,
  isActive,
}): React.ReactElement => {
  const currentSelectAddressLat: number | any = pointCoords?.lat;
  const currentSelectAddressLng: number | any = pointCoords?.lng;
  const [isLoading, setLoading] = useState(true);
  const map = useMap();
  useEffect(() => {
    if (!isLoading) {
      map?.invalidateSize();

      // Disable dragging
      map.dragging.disable();

      // Move map to center
      map
        .setView(
          [currentSelectAddressLat, currentSelectAddressLng],
          strategies !== undefined && strategies.length > 3 ? 12 : 13,
        )
        .getCenter();

      // Re-enable dragging
      map.dragging.enable();
      setLoading(false);
    }
  }, [currentSelectAddressLat, currentSelectAddressLng]);
  return (
    <>
      {strategies !== undefined && strategies.length > 0 && (
        <>
          {strategies.map((strategy: IStrategyQuote, index: number) => {
            const lastPolyline = Object.values(
              strategy.polylineCoords[strategy.polylineCoords.length - 1],
            );
            return (
              <FeatureGroup key={index}>
                <QuotePolyline
                  positions={strategy.polylineCoords}
                  id={strategy.id}
                  // isActive={selectedStrategies.includes(Number(strategy.id))}
                  isActive={isActive}
                  power={strategy.power}
                  label={strategy.id}
                  lng={pointCoords.lng}
                  lat={pointCoords.lat}
                  point={strategy.point}
                  targetType={strategy.targetType}
                >
                  <QuoteMarker
                    label={strategy.pointCoords.label}
                    lng={lastPolyline[1]}
                    lat={lastPolyline[0]}
                    id={strategy.id}
                    position={false}
                    isLoading={isLoading}
                    point={strategy.point}
                    targetType={strategy.targetType}
                    discarded={strategy.discarded}
                  ></QuoteMarker>
                </QuotePolyline>
              </FeatureGroup>
            );
          })}
        </>
      )}
    </>
  );
};

export default QuotePolygon;
